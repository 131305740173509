<template>
  <div>
    <label>
      <div v-if="label" class="mb-1 block text-sm font-medium text-neutral-700">
        {{ label }}
      </div>
      <input
        ref="input"
        :value="value"
        :placeholder="placeholder"
        :disabled="disabled"
        :maxlength="maxlength"
        :pattern="pattern === '' ? false : pattern"
        :class="[
          'border focus:outline-none block h-12 w-full appearance-none rounded-sm border-neutral-100 bg-white px-4 text-sm text-neutral-900 placeholder-neutral-400 transition-all duration-300 focus:border-neutral-300',
          { 'cursor-not-allowed text-neutral-400': disabled },
        ]"
        :type="type"
        :step="step === '' ? false : step"
        v-on="{ ...$listeners, input: update }"
      />
    </label>
  </div>
</template>
<script>
export default {
  name: "TextField",
  props: {
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Number],
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    focus: {
      type: Boolean,
      default: false,
    },
    maxlength: {
      type: Number,
      default: -1,
    },
    pattern: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    step: {
      type: String,
      default: "",
    },
  },

  mounted() {
    if (this.focus) {
      this.$refs.input.focus();
    }
  },

  methods: {
    update(event) {
      this.$emit("input", event.target.value);
    },
  },
};
</script>
