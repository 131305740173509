<template>
  <button
    :disabled="disabled"
    :class="[
      'focus:outline-none inline-flex min-w-0 cursor-pointer items-center rounded-sm text-sm font-medium transition-all duration-300 focus:shadow-focus',
      // Base variants
      { 'shadow-inner': variant === 'fill' },
      { border: variant === 'outline' },
      { '': variant === 'alpha' },

      // Positioning
      { 'justify-center': !fullWidth },
      { 'justify-between': fullWidth },

      // Fill button color
      {
        'bg-primary-600 text-white hover:bg-primary-500':
          variant === 'fill' && fillColor === 'primary' && !disabled,
      },
      {
        'cursor-not-allowed bg-neutral-200 text-white shadow-none':
          variant === 'fill' && fillColor === 'primary' && disabled,
      },
      {
        'bg-secondary-600 text-white hover:bg-secondary-700':
          variant === 'fill' && fillColor === 'secondary' && !disabled,
      },
      {
        'bg-secondary-300 text-white':
          variant === 'fill' && fillColor === 'secondary' && disabled,
      },
      {
        'bg-neutral-800 text-white hover:bg-neutral-600':
          variant === 'fill' && fillColor === 'neutral' && !disabled,
      },
      {
        'bg-neutral-400 text-white':
          variant === 'fill' && fillColor === 'neutral' && disabled,
      },
      {
        'bg-white text-neutral-800 hover:bg-neutral-50':
          variant === 'fill' && fillColor === 'white' && !disabled,
      },
      {
        'bg-neutral-100 text-neutral-800':
          variant === 'fill' && fillColor === 'white' && disabled,
      },

      // Outline button color
      {
        'border-neutral-100 hover:border-neutral-200':
          variant === 'outline' && outlineColor === 'light', //@TODO: disabled state is missing
      },
      {
        'border-neutral-600 text-white hover:border-neutral-50':
          variant === 'outline' && outlineColor === 'dark',
      },

      // Alpha button color
      {
        'bg-alpha-light-10 hover:bg-alpha-light-20':
          variant === 'alpha' && alphaColor === 'light', //@TODO: disabled state is missing
      },
      {
        'bg-alpha-dark-10 text-white hover:bg-alpha-dark-20':
          variant === 'alpha' && alphaColor === 'dark',
      },

      // Sizes
      { 'h-8 px-4 py-1': size === 'small' },

      { 'h-10 py-2': size === 'medium' },
      { 'px-5': size === 'medium' && hasText },
      { 'px-2': size === 'medium' && !hasText },
      { 'pl-3': size === 'medium' && hasIconLeft && hasText },
      { 'pr-3': size === 'medium' && hasIconRight && hasText },

      { 'h-12': size === 'large' },
      { 'px-6': size === 'large' && hasText },
      { 'px-3': size === 'large' && !hasText },
      { 'pl-3': size === 'large' && hasIconLeft && hasText },
      { 'pr-3': size === 'large' && hasIconRight && hasText },

      { 'py-4': size === 'extraLarge' },
      { 'px-8 ': size === 'extraLarge' && hasText },
      { 'px-4 ': size === 'extraLarge' && !hasText },
      { 'pl-4': size === 'extraLarge' && hasIconLeft && hasText },
      { 'pr-4': size === 'extraLarge' && hasIconRight && hasText },

      { 'group w-full': fullWidth },
    ]"
    @click="emitClick"
  >
    <div
      :class="[
        { 'mr-2': size === 'medium' && hasIconLeft && hasText },
        { 'mr-3': size === 'large' && hasIconLeft && hasText },
        { 'mr-4': size === 'extraLarge' && hasIconLeft && hasText },
        { 'w-10': fullWidth && hasIconRight },
        {
          'flex w-8 justify-center': fullWidth && hasIconLeft,
        },
      ]"
    >
      <slot name="iconLeft"></slot>
    </div>
    <div class="truncate w-full text-center">
      {{ text }}
      <slot name="iconCenter"></slot>
    </div>
    <div
      :class="[
        { 'ml-2': size === 'medium' && hasIconRight && hasText },
        { 'ml-3': size === 'large' && hasIconRight && hasText },
        { 'ml-4': size === 'extraLarge' && hasIconRight && hasText },
        { 'w-10': fullWidth && hasIconLeft },

        {
          'flex w-8 justify-center': fullWidth && hasIconRight,
        },
      ]"
    >
      <slot name="iconRight"></slot>
    </div>
  </button>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: undefined,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "large",
      validator: function (value) {
        return ["small", "medium", "large", "extraLarge"].indexOf(value) !== -1;
      },
    },
    variant: {
      type: String,
      default: "fill",
      validator: function (value) {
        return ["fill", "outline", "alpha"].indexOf(value) !== -1;
      },
    },
    fillColor: {
      type: String,
      default: "primary",
      validator: function (value) {
        return (
          ["primary", "secondary", "neutral", "white"].indexOf(value) !== -1
        );
      },
    },
    outlineColor: {
      type: String,
      default: "light",
      validator: function (value) {
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
    alphaColor: {
      type: String,
      default: "light",
      validator: function (value) {
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
  computed: {
    hasIconLeft() {
      return !!this.$slots.iconLeft;
    },
    hasIconRight() {
      return !!this.$slots.iconRight;
    },
    hasText() {
      return this.text !== undefined;
    },
  },
  mounted() {
    if (this.text && this.$slots.iconCenter) {
      throw "[Button] Cannot have both text and iconCenter, use iconLeft or iconRight";
    }
  },
  methods: {
    emitClick() {
      this.$emit("buttonClick");
    },
  },
};
</script>
