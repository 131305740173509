<template>
  <div class="pt-14 pb-12">
    <h1 class="mb-2 font-serif text-3xl font-bold text-primary-600 lg:text-4xl">
      <span>Ciao,</span>
      <span v-if="currentUser.firstName" class="block">
        {{ currentUser.firstName }}
      </span>
    </h1>
    <p class="text-neutral-400">
      {{ $t("pages.home.welcomeDescription") }}
    </p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("user", ["currentUser"]),
  },
};
</script>
