import graphqlClient from "@/utils/graphql";
import i18n from "@/i18n";
import CREATE_PRE_OPENING from "@/graphql/CreatePreOpening.gql";
import { sortAsc } from "@/utils/sortArray";

export const namespaced = true;

const emptyState = {
  formError: null,

  storeUuid: null,
  preOpeningStages: [],
};

export const state = {
  ...emptyState,
};

export const getters = {
  formError: (state) => state.formError,
  lastDueDate: (state) => {
    const sortedStages = state.preOpeningStages.sort(sortAsc("dueDate"));
    return sortedStages[0].dueDate;
  },
};

export const mutations = {
  resetState: (state) => {
    Object.assign(state, emptyState);
  },
  setStoreUuid: (state, storeUuid) => {
    state.storeUuid = storeUuid;
  },
  setPreOpeningStages: (state, payload) => {
    state.preOpeningStages = payload;
  },
  resetFormError: (state) => {
    state.formError = null;
  },
  setFormError: (state, error) => {
    state.formError = error;
  },
};

export const actions = {
  resetForm: ({ commit }) => {
    commit("resetState");
    commit("resetFormError");
  },
  updateStoreUuid: ({ commit }, storeUuid) => {
    commit("setStoreUuid", storeUuid);
  },
  updatePreOpeningStages: ({ commit }, payload) => {
    const preOpeningStages = payload.map((item) => {
      return {
        preOpeningStageUuid: item.details.uuid,
        dueDate: item.value,
      };
    });

    commit("setPreOpeningStages", preOpeningStages);
  },
  createPreOpening: async ({ state, getters, commit }) => {
    commit("resetFormError");

    if (state.storeUuid === null || state.storeUuid === "") {
      commit(
        "setFormError",
        i18n.t("components.operations.preOpening.form.errorNoStoreSelected"),
      );
      return;
    }

    const unsetItems = state.preOpeningStages.filter(
      (stage) => stage.dueDate === null || stage.dueDate === "",
    );
    if (unsetItems.length > 0) {
      commit(
        "setFormError",
        i18n.t("components.operations.preOpening.form.errorNotAllDueDatesSet"),
      );
      return;
    }

    try {
      await graphqlClient.mutate({
        mutation: CREATE_PRE_OPENING,
        variables: {
          storeUuid: state.storeUuid,
          preOpeningStages: state.preOpeningStages,
          endDate: getters.lastDueDate,
        },
      });
    } catch (error) {
      commit("setFormError", error.message);
    }
  },
};
