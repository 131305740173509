var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'relative flex flex-shrink-0 items-center justify-center rounded-full font-medium uppercase',
    { 'bg-primary-50 text-primary-400': _vm.variant === 'primary' },
    { 'bg-secondary-50 text-secondary-400': _vm.variant === 'secondary' },
    { 'bg-neutral-600 text-neutral-200': _vm.variant === 'neutral-dark' },
    { 'bg-neutral-50 text-neutral-300': _vm.variant === 'neutral' },
    { 'bg-danger-50 text-danger-600': _vm.variant === 'danger' },
    { 'h-6 w-6 text-xs': _vm.size === 'xs' },
    { 'h-8 w-8': _vm.size === 'sm' },
    { 'h-10 w-10': _vm.size === 'md' },
    { 'h-12 w-12 text-lg': _vm.size === 'lg' },
    { 'h-16 w-16 text-xl': _vm.size === 'xl' } ]},[(_vm.user.firstName)?_c('span',[_vm._v(_vm._s(_vm.user.firstName.charAt(0)))]):_vm._e(),(_vm.user.lastName)?_c('span',[_vm._v(_vm._s(_vm.user.lastName.charAt(0)))]):_vm._e(),(!_vm.user.firstName && !_vm.user.lastName)?_c('span',[_vm._v("?")]):_vm._e(),(_vm.hasCrown)?_c('IconCrown',{staticClass:"absolute bottom-full -mb-2 h-6 w-6"}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }