import graphqlClient from "@/utils/graphql";
import FIND_DISHES from "@/graphql/FindDishes.gql";
import FIND_DISH from "@/graphql/FindDish.gql";
import FIND_DISH_COURSES from "@/graphql/FindDishCourses.gql";
import i18n from "@/i18n";

export const namespaced = true;

export const state = {
  dishCourses: [],
  dishes: [],
  activeCourseUuid: null,
  dish: null,
  dishError: null,
  dishesError: null,
  dishCutlery: [
    {
      id: 1,
      name: i18n.t("components.menus.cutleryOption1"),
    },
    {
      id: 2,
      name: i18n.t("components.menus.cutleryOption2"),
    },
    {
      id: 3,
      name: i18n.t("components.menus.cutleryOption3"),
    },
    {
      id: 4,
      name: i18n.t("components.menus.cutleryOption4"),
    },
    {
      id: 5,
      name: i18n.t("components.menus.cutleryOption5"),
    },
    {
      id: 6,
      name: i18n.t("components.menus.cutleryOption6"),
    },
    {
      id: 7,
      name: i18n.t("components.menus.cutleryOption7"),
    },
    {
      id: 8,
      name: i18n.t("components.menus.cutleryOption8"),
    },
    {
      id: 9,
      name: i18n.t("components.menus.cutleryOption9"),
    },
  ],
};

export const getters = {
  dishCourses: (state) => state.dishCourses,
  activeCourseUuid: (state) => state.activeCourseUuid,
  dishes: (state) => state.dishes,
  dishesError: (state) => state.dishesError,
  dish: (state) => state.dish,
  dishError: (state) => state.dishError,
  dishCutlery: (state) => state.dishCutlery,
};

export const mutations = {
  setDishCourses: (state, dishCourses) => {
    state.dishCourses = dishCourses;
  },
  setActiveCourseUuid: (state, courseUuid) => {
    state.activeCourseUuid = courseUuid;
  },
  setDishes: (state, dishes) => {
    state.dishes = dishes;
  },
  setDishesError: (state, error) => {
    state.dishesError = error;
  },
  resetDishesError: (state) => {
    state.dishesError = null;
  },
  setDish: (state, dish) => {
    state.dish = dish;
  },
  setDishError: (state, error) => {
    state.dishError = error;
  },
  resetDishError: (state) => {
    state.dishError = null;
  },
};

export const actions = {
  fetchDishCourses: async ({ state, commit, dispatch }) => {
    const response = await graphqlClient.query({
      query: FIND_DISH_COURSES,
    });

    const dishCourses = response.data.dishCourses;

    commit("setDishCourses", dishCourses);

    if (state.activeCourseUuid === null) {
      const firstCourseWithDishes = dishCourses.find(
        (course) => course.dishCount > 0,
      );

      const newActiveCourseUuid = firstCourseWithDishes
        ? firstCourseWithDishes.uuid
        : dishCourses[0].uuid;

      dispatch("updateActiveCourseUuid", newActiveCourseUuid);
    }
  },

  updateActiveCourseUuid: ({ commit, dispatch }, courseUuid) => {
    commit("setActiveCourseUuid", courseUuid);
    dispatch("fetchDishes");
  },

  fetchDishes: async ({ commit }) => {
    if (state.activeCourseUuid) {
      try {
        const response = await graphqlClient.query({
          query: FIND_DISHES,
          variables: {
            courseUuid: state.activeCourseUuid,
          },
        });

        const dishes = response.data.dishes;

        commit("setDishes", dishes);
        commit("resetDishesError");
      } catch (error) {
        commit("setDishesError", JSON.stringify(error.message));
      }
    }
  },

  fetchDish: async ({ commit }, payload) => {
    try {
      const response = await graphqlClient.query({
        query: FIND_DISH,
        variables: {
          uuid: payload.dishUuid,
        },
      });

      const dish = response.data.dish;
      commit("setDish", dish);
      commit("resetDishError");
    } catch (error) {
      commit("setDishError", JSON.stringify(error.message));
    }
  },
};
