import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/pages/landing/Home.vue";
import NotFound from "@/pages/NotFound.vue";
import login from "@/auth/login";
import PermissionGuard from "@/permissions/permissionGuard";
import store from "@/store";

import i18n from "@/i18n/";

const permissionGuard = new PermissionGuard(store);

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      title: i18n.t("pages.home.pageTitle"),
    },
  },
  {
    path: "/brand-marketing",
    props: true,
    component: () =>
      /* webpackChunkName: "brand-marketing" */ import(
        "@/pages/brand-marketing/BrandMarketing.vue"
      ),
    meta: {
      title: i18n.t("pages.brandMarketing.pageTitle"),
    },
    children: [
      {
        name: "brand-marketing-packages",
        path: "",
        props: true,
        component: () =>
          /* webpackChunkName: "brand-marketing" */ import(
            "@/pages/brand-marketing/BrandPackagesOverview.vue"
          ),
        beforeEnter: permissionGuard.onlyAllowPermission("read:assetPackages"),
        meta: {
          title: i18n.t("pages.brandPackagesOverview.pageTitle"),
        },
      },
      {
        name: "brand-marketing-all-assets",
        path: "all-assets",
        component: () =>
          /* webpackChunkName: "brand-marketing" */ import(
            "@/pages/brand-marketing/BrandAllAssets.vue"
          ),
        props: true,
        beforeEnter: permissionGuard.onlyAllowPermission("read:assets"),
        meta: {
          title: i18n.t("pages.brandAllAssets.pageTitle"),
        },
      },
      {
        name: "brand-marketing-packages-detail",
        path: "packages/:packageUuid",
        component: () =>
          /* webpackChunkName: "brand-marketing" */ import(
            "@/pages/brand-marketing/BrandPackagesDetail.vue"
          ),
        props: (route) => ({
          packageUuid: route.params.packageUuid,
          backLinkRouteName: "brand-marketing-packages",
        }),
        beforeEnter: permissionGuard.onlyAllowPermission("read:assetPackages"),
        meta: {
          title: i18n.t("pages.brandPackagesDetail.pageTitle"),
        },
      },
      {
        name: "brand-marketing-packages-not-authorized",
        path: "not-authorized",
        component: () =>
          /* webpackChunkName: "brand-marketing" */ import(
            "@/pages/brand-marketing/BrandPackagesNotAuthorized.vue"
          ),
        beforeEnter: permissionGuard.onlyAllowPermission("read:assetPackages"),
        meta: {
          title: i18n.t("pages.brandPackagesNotAuthorized.pageTitle"),
        },
      },
    ],
  },
  {
    path: "/menus",
    beforeEnter: permissionGuard.onlyAllowPermission("read:menu"),
    component: () =>
      /* webpackChunkName: "menus" */ import("@/pages/menus/Menus.vue"),
    children: [
      {
        name: "master-dish-list",
        path: "",
        component: () =>
          /* webpackChunkName: "master-dish-list" */ import(
            "@/pages/menus/MasterDishList.vue"
          ),
        beforeEnter: permissionGuard.onlyAllowPermission("read:menu"),
        meta: {
          title: i18n.t("pages.masterDishList.pageTitle"),
        },
      },
      {
        name: "core-menus",
        path: "core-menus",
        component: () =>
          /* webpackChunkName: "master-dish-list" */ import(
            "@/pages/menus/CoreMenus.vue"
          ),
        beforeEnter: permissionGuard.onlyAllowPermission("write:menu"),
        meta: {
          title: i18n.t("pages.coreMenus.pageTitle"),
        },
      },
      {
        name: "change-requests",
        path: "change-requests",
        component: () =>
          /* webpackChunkName: "master-dish-list" */ import(
            "@/pages/menus/ChangeRequests.vue"
          ),
        beforeEnter: permissionGuard.onlyAllowPermission("write:changeRequest"),
        meta: {
          title: i18n.t("pages.changeRequests.pageTitle"),
        },
      },
      {
        name: "create-dish",
        path: "dish/create",
        component: () =>
          /* webpackChunkName: "dish-create" */ import(
            "@/pages/menus/DishCreate.vue"
          ),
        props: true,
        beforeEnter: permissionGuard.onlyAllowPermission("write:menu"),
        meta: {
          title: i18n.t("pages.dishCreate.pageTitle"),
        },
      },
      {
        name: "dish-detail",
        path: "dish/:dishUuid",
        component: () =>
          /* webpackChunkName: "dish-detail" */ import(
            "@/pages/menus/DishDetail.vue"
          ),
        props: true,
        beforeEnter: permissionGuard.onlyAllowPermission("read:menu"),
        meta: {
          title: i18n.t("pages.brandPackagesDetail.pageTitle"),
        },
      },
      {
        name: "edit-dish",
        path: "dish/:dishUuid/edit",
        component: () =>
          /* webpackChunkName: "edit-dish" */ import(
            "@/pages/menus/DishEdit.vue"
          ),
        props: true,
        beforeEnter: permissionGuard.onlyAllowPermission("read:menu"),
        meta: {
          title: i18n.t("pages.dishEdit.pageTitle"),
        },
      },
      {
        name: "my-menu",
        path: "my-menu",
        component: () =>
          /* webpackChunkName: "my-menu" */ import("@/pages/menus/MyMenu.vue"),
        beforeEnter:
          permissionGuard.onlyAllowPermission("read:menu") &&
          !permissionGuard.onlyAllowPermission("write:menu"),
        meta: {
          title: i18n.t("pages.myMenu.pageTitle"),
        },
      },
    ],
  },
  {
    path: "/user-management",
    beforeEnter: permissionGuard.onlyAllowPermission("read:organisations"),
    component: () =>
      /* webpackChunkName: "user-management" */ import(
        "@/pages/user-management/UserManagement.vue"
      ),
    children: [
      {
        name: "organisations",
        path: "",
        component: () =>
          /* webpackChunkName: "user-management" */ import(
            "@/pages/user-management/ManageOrganisations.vue"
          ),
        meta: {
          title: i18n.t("pages.manageOrganisations.pageTitle"),
        },
      },
      {
        name: "users",
        path: "users",
        component: () =>
          /* webpackChunkName: "user-management" */ import(
            "@/pages/user-management/ManageUsers.vue"
          ),
        meta: {
          title: i18n.t("pages.manageUsers.pageTitle"),
        },
      },
      {
        name: "stores",
        path: "store-locations",
        component: () =>
          /* webpackChunkName: "user-management" */ import(
            "@/pages/user-management/ManageStores.vue"
          ),
        meta: {
          title: i18n.t("pages.manageStores.pageTitle"),
        },
      },
    ],
  },
  {
    path: "/operations",
    beforeEnter: permissionGuard.onlyAllowPermission("read:preOpenings"),
    component: () =>
      /* webpackChunkName: "operations" */ import(
        "@/pages/operations/Operations.vue"
      ),
    children: [
      {
        name: "pre-opening-overview",
        path: "",
        component: () =>
          /* webpackChunkName: "pre-opening" */ import(
            "@/pages/operations/PreOpeningOverview.vue"
          ),
        meta: {
          title: i18n.t("pages.operations.title"),
        },
      },
      {
        name: "pre-opening-board",
        path: "board/:preOpeningUuid",
        component: () =>
          /* webpackChunkName: "pre-opening" */ import(
            "@/pages/operations/PreOpeningBoard.vue"
          ),
        meta: {
          title: i18n.t("pages.operations.title"),
        },
      },
      {
        name: "manage-pre-opening-stages",
        path: "stages",
        component: () =>
          /* webpackChunkName: "pre-opening" */ import(
            "@/pages/operations/ManagePreOpeningStages.vue"
          ),
        beforeEnter: permissionGuard.onlyAllowPermission("write:preOpenings"),
        meta: {
          title: i18n.t("pages.operations.title"),
        },
      },
      {
        name: "operations-resources",
        path: "resources",
        component: () =>
          /* webpackChunkName: "pre-opening" */ import(
            "@/pages/operations/OperationsResources.vue"
          ),
        meta: {
          title: i18n.t("pages.operations.title"),
        },
      },
      {
        name: "operations-resources-packages-detail",
        path: "resources/:packageUuid",
        component: () =>
          /* webpackChunkName: "brand-marketing" */ import(
            "@/pages/brand-marketing/BrandPackagesDetail.vue"
          ),
        props: (route) => ({
          packageUuid: route.params.packageUuid,
          backLinkRouteName: "operations-resources",
        }),
        beforeEnter: permissionGuard.onlyAllowPermission("read:assetPackages"),
        meta: {
          title: i18n.t("pages.brandPackagesDetail.pageTitle"),
        },
      },
    ],
  },
  {
    path: "/community",
    beforeEnter: permissionGuard.onlyAllowPermission("read:community"),
    component: () =>
      /* webpackChunkName: "community" */ import(
        "@/pages/community/Community.vue"
      ),
    children: [
      {
        name: "all-proposals",
        path: "",
        component: () =>
          /* webpackChunkName: "all-proposals" */ import(
            "@/pages/community/AllProposals.vue"
          ),
        meta: {
          title: i18n.t("pages.community.title"),
        },
      },
      {
        name: "my-proposals",
        path: "my-proposals",
        component: () =>
          /* webpackChunkName: "my-proposals" */ import(
            "@/pages/community/MyProposals.vue"
          ),
        meta: {
          title: i18n.t("pages.community.title"),
        },
      },
    ],
  },
  {
    path: "/not-authorized",
    name: "not-authorized",
    component: () => import("@/pages/NotAuthorized.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(_to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

router.addRoute({
  path: "*",
  component: NotFound,
});

router.beforeEach((to, _from, next) => {
  return login(to, _from, next, store);
});

router.beforeEach((_to, _from, next) => {
  store.dispatch("setRouteLoading", true);
  next();
});
router.afterEach(() => {
  store.dispatch("setRouteLoading", false);
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title
      ? to.meta.title
      : i18n.t("global.digitalDon");
  });
});

export default router;
