<template>
  <div :class="['bg-neutral-900', { 'h-screen lg:h-auto': !contentVisible }]">
    <div class="flex h-full flex-col flex-wrap items-center justify-center">
      <div
        class="flex h-12 w-full items-center justify-between container lg:h-14"
      >
        <router-link to="/" class="text-white">
          <Logo class="h-10 w-10 lg:h-12 lg:w-12" />
        </router-link>

        <div
          class="flex min-w-0 cursor-pointer items-center p-2 lg:hidden lg:p-4"
          @click="toggleMenu"
        >
          <span class="truncate mr-1 text-sm font-medium text-white">
            {{ $route.meta.title || $t("components.navigation.navbar.menu") }}
          </span>
          <div
            class="inline-block text-neutral-500 transition-all"
            :class="{ 'rotate-180': showMenu }"
          >
            <IconChevronDown />
          </div>
        </div>
        <NavItems class="hidden lg:flex"></NavItems>
        <div class="p-1 pr-0" @click="toggleMenu">
          <UserAvatar
            v-if="currentUser"
            class="lg:hidden"
            size="sm"
            variant="neutral-dark"
            :user="currentUser"
          />
          <ToolsPopup class="hidden lg:flex"></ToolsPopup>
        </div>
      </div>

      <NavItems
        :show-menu="showMenu"
        :class="[showMenu ? 'flex-grow flex flex-col lg:hidden' : 'hidden']"
        @navClick="toggleMenu"
      ></NavItems>
    </div>
  </div>
</template>

<script>
import NavItems from "@/components/navigation/NavItems";
import ToolsPopup from "@/components/tools/ToolsPopup";
import UserAvatar from "@/components/common/UserAvatar";

import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    NavItems,
    ToolsPopup,
    UserAvatar,
  },
  data() {
    return {
      showMenu: false,
    };
  },
  computed: {
    ...mapGetters("user", ["currentUser"]),
    ...mapGetters("content", ["contentVisible"]),
  },
  methods: {
    ...mapActions("content", ["toggleContentVisibility"]),
    toggleMenu() {
      this.showMenu = !this.showMenu;
      this.toggleContentVisibility();
    },
  },
};
</script>
