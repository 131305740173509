export const namespaced = true;

export const state = {
  hasSetPermissions: false,
  permissions: [],
};

export const getters = {
  hasSetPermissions: (state) => state.hasSetPermissions,
  hasPermission: (state) => (requestedPermission) => {
    const hasPermission = state.permissions.some(
      (userPermission) => userPermission === requestedPermission,
    );
    return hasPermission;
  },
};

export const mutations = {
  setPermissions: (state, payload) => {
    state.permissions = payload;
    state.hasSetPermissions = true;
  },
};

export const actions = {
  setPermissions: ({ commit }, payload) => {
    commit("setPermissions", payload);
  },
};
