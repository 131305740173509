import graphqlClient from "@/utils/graphql";
import FETCH_ALL_PRE_OPENING_STAGES from "@/graphql/FetchAllPreOpeningStages.gql";

export const namespaced = true;

export const state = {
  preOpeningStages: null,
  preOpeningStagesLoaded: false,
};

export const getters = {
  preOpeningStages: (state) => state.preOpeningStages,
};

export const mutations = {
  setPreOpeningStages: (state, preOpeningStages) => {
    state.preOpeningStages = preOpeningStages;
    state.preOpeningStagesLoaded = true;
  },
};

export const actions = {
  fetchPreOpeningStages: async ({ state, commit }) => {
    if (state.preOpeningStagesLoaded) {
      return;
    }

    try {
      const response = await graphqlClient.query({
        query: FETCH_ALL_PRE_OPENING_STAGES,
      });

      const preOpeningStages = response.data.preOpeningStages;

      commit("setPreOpeningStages", preOpeningStages);
    } catch (error) {
      commit("setError", JSON.stringify(error.message));
    }
  },
};
