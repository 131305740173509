import graphqlClient from "@/utils/graphql";
import FIND_MY_CORE_MENU from "@/graphql/FindMyCoreMenu.gql";

export const namespaced = true;

export const state = {
  myMenu: null,
  myMenuError: null,
};

export const getters = {
  myMenu: (state) => state.myMenu,
  myMenuError: (state) => state.myMenuError,
};

export const mutations = {
  setMyMenu: (state, myMenu) => {
    state.myMenu = myMenu;
  },

  setMyMenuError: (state, error) => {
    state.myMenuError = error;
  },
};

export const actions = {
  findMyMenu: async ({ commit, rootGetters }) => {
    try {
      const response = await graphqlClient.query({
        query: FIND_MY_CORE_MENU,
      });

      const coreMenu = response.data.myCoreMenu;

      if (coreMenu) {
        const courses = rootGetters["dish/dishCourses"];

        coreMenu.courses = courses.map((course) => {
          course.dishes = [];

          coreMenu.dishes.map((dish) => {
            if (
              dish.course.uuid === course.uuid &&
              !course.dishes.find((d) => d.uuid === dish.uuid)
            ) {
              course.dishes.push(dish);
            }
          });

          return course;
        });
      }

      commit("setMyMenu", coreMenu);
    } catch (error) {
      commit("setMyMenuError", JSON.stringify(error.message));
    }
  },
};
