import graphqlClient from "@/utils/graphql";
import FIND_STORES from "@/graphql/FindStores.gql";

export const namespaced = true;

export const state = {
  stores: [],
  error: null,
};

export const getters = {
  stores: (state) => state.stores,
};

export const mutations = {
  setStores: (state, stores) => {
    state.stores = stores;
  },
  setError: (state, error) => {
    state.error = error;
  },
  resetError: (state) => {
    state.error = null;
  },
};

export const actions = {
  fetchStores: async ({ commit }, { withoutPreOpenings }) => {
    commit("resetError");

    try {
      const response = await graphqlClient.query({
        query: FIND_STORES,
        variables: {
          withoutPreOpenings: !!withoutPreOpenings,
        },
      });

      const stores = response.data.stores;

      commit("setStores", stores);
    } catch (error) {
      commit("setError", JSON.stringify(error.message));
    }
  },
};
