var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex w-full flex-wrap",on:{"click":_vm.emitClick}},[(_vm.$can('read:organisations'))?_c('router-link',{class:[
      'rounded group inline-block flex cursor-pointer flex-col items-center px-2 py-3 transition-all duration-200',
      {
        'w-1/2 text-neutral-400 hover:text-neutral-600 active:text-neutral-800':
          _vm.variant === 'light',
      },
      {
        'mr-2 w-1/4 bg-neutral-700 py-4 text-neutral-400 hover:bg-neutral-600 hover:text-neutral-100 sm:mr-4':
          _vm.variant === 'dark',
      } ],attrs:{"to":"/user-management"}},[_c('IconUserGroup'),_c('span',{class:[
        'mt-1 text-center text-xs-relaxed transition-all transition-all duration-200 duration-200',
        {
          'text-neutral-600 group-hover:text-neutral-800': _vm.variant == 'light',
        } ]},[_vm._v(" "+_vm._s(_vm.$t("components.tools.toolsGrid.manageUsers"))+" ")])],1):_vm._e(),_c('div',{class:[
      'rounded group inline-block flex cursor-pointer flex-col items-center px-2 py-3 transition-all duration-200',
      {
        'w-1/2 text-neutral-400 hover:text-neutral-600 active:text-neutral-800':
          _vm.variant === 'light',
      },
      {
        'mr-2 w-1/4 bg-neutral-700 py-4 text-neutral-400 hover:bg-neutral-600 hover:text-neutral-100 sm:mr-4':
          _vm.variant === 'dark',
      } ],on:{"click":_vm.logout}},[_c('IconPower'),_c('span',{class:[
        'mt-1 text-center text-xs-relaxed transition-all duration-200',
        {
          'text-neutral-600 group-hover:text-neutral-800': _vm.variant == 'light',
        } ]},[_vm._v(" "+_vm._s(_vm.$t("components.tools.toolsGrid.logout"))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }