export const namespaced = true;

export const state = {
  selectedAssets: [],
  showPackageBuilder: false,
  currentStep: "selectAssets",
};

export const getters = {
  selectedAssets: (state) => state.selectedAssets,
  isAssetSelected: (state) => (asset) => {
    const selectedAsset = state.selectedAssets.filter(
      (selectedAsset) => selectedAsset.uuid === asset.uuid,
    );
    return selectedAsset.length > 0;
  },
  showPackageBuilder: (state) => state.showPackageBuilder,
  currentStep: (state) => state.currentStep,
};

export const mutations = {
  toggleSelectAsset: (state, asset) => {
    if (state.selectedAssets.includes(asset)) {
      state.selectedAssets.splice(state.selectedAssets.indexOf(asset), 1);
    } else {
      state.selectedAssets.push(asset);
    }
  },
  clearSelectedAssets: (state) => {
    state.selectedAssets = [];
  },
  setPackageBuilder: (state, payload) => {
    state.showPackageBuilder = payload;
  },
  setCurrentStep: (state, payload) => {
    state.currentStep = payload;
  },
};

export const actions = {
  toggleSelectAsset: ({ commit, state }, asset) => {
    if (state.currentStep === "successfullyAddedAssets") {
      commit("setCurrentStep", "selectAssets");
    }

    commit("toggleSelectAsset", asset);

    if (state.selectedAssets.length > 0) {
      commit("setPackageBuilder", true);
    } else {
      commit("setPackageBuilder", false);
    }
  },
  clearSelectedAssets: ({ commit }) => {
    commit("clearSelectedAssets");
  },
  hidePackageBuilder: ({ commit }) => {
    commit("setPackageBuilder", false);
    commit("clearSelectedAssets");
  },
  setCurrentStep: ({ commit }, payload) => {
    commit("setCurrentStep", payload);
  },
};
