import Vue from "vue";
import Vuex from "vuex";
import * as loading from "@/store/modules/loading";
import * as content from "@/store/modules/content";
import * as packageBuilder from "@/store/modules/packageBuilder";
import * as permissions from "@/store/modules/permissions";
import * as user from "@/store/modules/user";
import * as dish from "@/store/modules/dish";
import * as dishForm from "@/store/modules/dishForm";
import * as preOpenings from "@/store/modules/preOpenings";
import * as preOpeningForm from "@/store/modules/preOpeningForm";
import * as singlePreOpening from "@/store/modules/singlePreOpening";
import * as preOpeningResources from "@/store/modules/preOpeningResources";
import * as preOpeningStages from "@/store/modules/preOpeningStages";
import * as stats from "@/store/modules/stats";
import * as assetPackage from "@/store/modules/assetPackage";
import * as dishProposalForm from "@/store/modules/dishProposalForm";
import * as dishProposals from "@/store/modules/dishProposals";
import * as changeRequests from "@/store/modules/changeRequests";
import * as coreMenu from "@/store/modules/coreMenu";
import * as myMenu from "@/store/modules/myMenu";
import * as stores from "@/store/modules/stores";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    loading,
    content,
    packageBuilder,
    permissions,
    user,
    dish,
    dishForm,
    preOpenings,
    preOpeningForm,
    singlePreOpening,
    preOpeningResources,
    preOpeningStages,
    stats,
    assetPackage,
    dishProposalForm,
    dishProposals,
    changeRequests,
    coreMenu,
    myMenu,
    stores,
  },
});
