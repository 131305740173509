import graphqlClient from "@/utils/graphql";
import USER_DOWNLOADED_ASSET_PACKAGE from "@/graphql/UserDownloadedAssetPackage.gql";

export const namespaced = true;

export const actions = {
  downloadAssetPackage: async ({ rootGetters }, assetPackage) => {
    const currentUser = rootGetters["user/currentUser"];
    await graphqlClient.mutate({
      mutation: USER_DOWNLOADED_ASSET_PACKAGE,
      variables: {
        assetPackageUuid: assetPackage.uuid,
        userUuid: currentUser.uuid,
      },
    });
    window.location = assetPackage.file.url;
  },
};
