<template>
  <div
    class="flex-grow w-screen items-center overflow-y-scroll bg-neutral-800 p-5 lg:order-none lg:h-full lg:w-auto lg:flex-grow-0 lg:overflow-y-auto lg:bg-transparent lg:p-0"
    @click="emitClick"
  >
    <router-link
      to="/"
      class="relative mx-6 flex w-full cursor-pointer items-center py-3 pt-0 text-sm font-medium text-neutral-400 transition-all duration-300 hover:text-white lg:h-full lg:w-auto lg:py-0"
      exact
    >
      <span>{{ $t("components.navigation.navItems.overview") }}</span>
      <span
        class="border-b absolute bottom-0 left-0 right-0 top-0 origin-right transform border-neutral-700 transition-transform duration-300 lg:scale-x-0 lg:border-b-4 lg:border-primary-50"
      ></span>
    </router-link>
    <router-link
      v-if="$can('read:assetPackages')"
      to="/brand-marketing"
      class="relative mx-6 flex w-full cursor-pointer items-center py-3 text-sm font-medium text-neutral-400 transition-all duration-300 hover:text-white lg:h-full lg:w-auto lg:py-0"
    >
      <span>{{ $t("components.navigation.navItems.brandMarketing") }}</span>
      <span
        class="border-b absolute bottom-0 left-0 right-0 top-0 origin-right transform border-neutral-700 transition-transform duration-300 lg:scale-x-0 lg:border-b-4 lg:border-primary-50"
      ></span>
    </router-link>
    <router-link
      v-if="$can('read:menu')"
      to="/menus"
      class="relative mx-6 flex w-full cursor-pointer items-center py-3 text-sm font-medium text-neutral-400 transition-all duration-300 hover:text-white lg:h-full lg:w-auto lg:py-0"
    >
      <span>{{ $t("components.navigation.navItems.menus") }}</span>
      <span
        class="border-b absolute bottom-0 left-0 right-0 top-0 origin-right transform border-neutral-700 transition-transform duration-300 lg:scale-x-0 lg:border-b-4 lg:border-primary-50"
      ></span>
    </router-link>
    <router-link
      v-if="$can('read:preOpenings')"
      to="/operations"
      class="relative mx-6 flex w-full cursor-pointer items-center py-3 text-sm font-medium text-neutral-400 transition-all duration-300 hover:text-white lg:h-full lg:w-auto lg:py-0"
    >
      <span>{{ $t("components.navigation.navItems.operations") }}</span>
      <span
        class="border-b absolute bottom-0 left-0 right-0 top-0 origin-right transform border-neutral-700 transition-transform duration-300 lg:scale-x-0 lg:border-b-4 lg:border-primary-50"
      ></span>
    </router-link>
    <router-link
      v-if="$can('read:community')"
      to="/community"
      class="relative mx-6 flex w-full cursor-pointer items-center py-3 text-sm font-medium text-neutral-400 transition-all duration-300 hover:text-white lg:h-full lg:w-auto lg:py-0"
    >
      <span>{{ $t("components.navigation.navItems.community") }}</span>
      <span
        class="border-b absolute bottom-0 left-0 right-0 top-0 origin-right transform border-neutral-700 transition-transform duration-300 lg:scale-x-0 lg:border-b-4 lg:border-primary-50"
      ></span>
    </router-link>
    <ToolsGrid
      v-if="showMenu"
      variant="dark"
      class="mt-10 lg:hidden"
    ></ToolsGrid>
  </div>
</template>

<script>
import ToolsGrid from "@/components/tools/ToolsGrid";

export default {
  components: { ToolsGrid },
  props: {
    showMenu: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    emitClick() {
      this.$emit("navClick");
    },
  },
};
</script>

<style scoped>
a.router-link-active {
  @apply text-white;

  & span:last-child {
    --transform-scale-x: 1;
    @apply origin-left;
  }
}
</style>
