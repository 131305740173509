import graphqlClient from "@/utils/graphql";
import FIND_CHANGE_REQUESTS from "@/graphql/FindChangeRequests.gql";
import REVIEW_DISH_PROPOSAL from "@/graphql/ReviewDishProposal.gql";

export const namespaced = true;

export const state = {
  changeRequests: [],
  sortedChangeRequests: [],
  changeRequestsLoaded: false,
  error: null,
};

export const getters = {
  changeRequests: (state) => state.changeRequests,
  closedChangeRequests: (state) =>
    state.changeRequests.filter(
      (changeRequest) => changeRequest.status === "CLOSED",
    ),
  error: (state) => state.error,
  sortedChangeRequests: (state, getters) => {
    const sortedChangeRequests = getters.changeRequests.sort((a, b) => {
      if (a.status !== "CLOSED" && b.status === "CLOSED") {
        return 1;
      }
      if (a.status === "CLOSED" && b.status !== "CLOSED") {
        return -1;
      }
      return 0;
    });

    return sortedChangeRequests;
  },
};

export const mutations = {
  setChangeRequests: (state, changeRequests) => {
    state.changeRequests = changeRequests;
    state.changeRequestsLoaded = true;
  },
  setError: (state, error) => {
    state.error = error;
  },
  resetError: (state) => {
    state.error = null;
  },
  resetChangeRequestsLoaded: (state) => {
    state.changeRequestsLoaded = false;
  },
};

export const actions = {
  reviewChangeRequest: async (
    { commit, dispatch },
    {
      dishProposalUuid,
      status,
      text,
      estimatedPriceApproved,
      generalConceptApproved,
      productMixApproved,
      durationApproved,
    },
  ) => {
    try {
      await graphqlClient.mutate({
        mutation: REVIEW_DISH_PROPOSAL,
        variables: {
          uuid: dishProposalUuid,
          status,
          text,
          estimatedPriceApproved,
          generalConceptApproved,
          productMixApproved,
          durationApproved,
        },
      });

      dispatch("refetchChangeRequests");
    } catch (error) {
      commit("setError", JSON.stringify(error.message));
    }
  },
  refetchChangeRequests: async ({ commit, dispatch }) => {
    commit("resetChangeRequestsLoaded");
    dispatch("fetchChangeRequests");
  },
  fetchChangeRequests: async ({ commit }) => {
    try {
      if (state.changeRequestsLoaded) {
        return;
      }

      const response = await graphqlClient.query({
        query: FIND_CHANGE_REQUESTS,
      });

      const changeRequests = response.data.dishProposals;

      commit("resetError");
      commit("setChangeRequests", changeRequests);
    } catch (error) {
      commit("setError", JSON.stringify(error.message));
    }
  },
};
