import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import env from "./env";

import { createProvider } from "./vue-apollo";
import { authApolloMiddleware } from "./utils/graphql";
import { Auth0Plugin } from "./auth";
import { PermissionPlugin } from "./permissions/permissionPlugin";
import { RolePlugin } from "./permissions/rolePlugin";
import "./registerGlobalComponents.js";
import "@/assets/css/app.css";

import VueLazyload from "vue-lazyload";
import i18n from "@/i18n/";
import CountryFlag from "vue-country-flag";
import VueScrollactive from "vue-scrollactive";

Vue.config.productionTip = false;

Vue.use(Auth0Plugin, {
  domain: env.value("VUE_APP_AUTH0_DOMAIN"),
  clientId: env.value("VUE_APP_AUTH0_CLIENT_ID"),
  audience: env.value("VUE_APP_AUTH0_AUDIENCE"),
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname,
    );
  },
});

Vue.use(PermissionPlugin);

Vue.use(RolePlugin);

Vue.use(VueLazyload);

Vue.use(VueScrollactive);

Vue.component("country-flag", CountryFlag);

new Vue({
  env,
  router,
  store,
  i18n,
  apolloProvider: createProvider({ link: authApolloMiddleware }),
  render: (h) => h(App),
}).$mount("#app");
