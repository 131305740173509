<template>
  <div class="relative">
    <div
      class="absolute top-0 right-0 h-full w-full bg-contain bg-right-top bg-no-repeat lg:w-8/12"
      :style="{ backgroundImage: `url(${background})` }"
    ></div>
    <div class="relative container">
      <LandingHero />
      <LandingCardGrid />
    </div>
  </div>
</template>

<script>
import LandingHero from "@/components/landing/LandingHero";
import LandingCardGrid from "@/components/landing/LandingCardGrid";
import background from "@/assets/images/landing/landing-background.png";
import store from "@/store";

export default {
  components: {
    LandingHero,
    LandingCardGrid,
  },
  data() {
    return {
      background,
    };
  },
  mounted() {
    if (this.$can("read:stats")) {
      Promise.all([
        store.dispatch("stats/fetchBrandMarketingStats"),
        store.dispatch("stats/fetchMenuStats"),
        store.dispatch("stats/fetchOperationStats"),
        store.dispatch("stats/fetchUserStats"),
      ]);
    }
  },
};
</script>
