<template>
  <div>
    <label>
      <div v-if="label" class="mb-1 block text-sm font-medium text-neutral-700">
        {{ label }}
      </div>
      <div class="relative">
        <select
          class="border focus:outline-none h-12 w-full cursor-pointer appearance-none rounded-sm border-neutral-100 bg-neutral-0 px-4 text-sm shadow-sm focus:border-neutral-300"
          @change="update"
        >
          <slot></slot>
        </select>
        <div
          class="pointer-events-none absolute right-0 top-0 bottom-0 flex items-center pr-2"
        >
          <IconSortArrow />
        </div>
      </div>
    </label>
  </div>
</template>

<script>
export default {
  name: "SelectField",
  props: {
    value: {
      type: [String, Number],
      required: false,
      default: "",
    },

    label: {
      type: String,
      required: false,
      default: null,
    },
  },
  methods: {
    update(event) {
      this.$emit("input", event.target.value);
    },
  },
};
</script>
