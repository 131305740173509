<template>
  <div>
    <label>
      <div
        class="mb-1 block flex items-center justify-between text-sm font-medium text-neutral-700"
      >
        <span>{{ label }}</span>
        <span v-if="counter" class="text-sm font-normal">
          {{ value.length }}
          <span class="text-neutral-400">/{{ maxlength }}</span>
        </span>
      </div>
      <textarea
        ref="textarea"
        :value="value"
        :placeholder="placeholder"
        :maxlength="maxlength"
        class="border focus:outline-none block h-32 w-full resize-none appearance-none rounded-sm border-neutral-100 bg-white px-4 py-3 text-sm text-neutral-900 placeholder-neutral-400 transition-all duration-300 focus:border-neutral-300"
        @input="update"
      >
      </textarea>
    </label>
  </div>
</template>

<script>
export default {
  name: "TextArea",
  props: {
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    value: {
      type: String,
      required: false,
      default: "",
    },
    focus: {
      type: Boolean,
      required: false,
      default: false,
    },
    maxlength: {
      type: Number,
      required: false,
      default: -1,
    },
    counter: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  mounted() {
    if (this.focus) {
      this.$refs.textarea.focus();
    }
  },

  methods: {
    update(event) {
      this.$emit("input", event.target.value);
    },
  },
};
</script>
