<template>
  <div v-click-outside="closeTools" class="relative z-10">
    <button class="focus:outline-none" @click="toggleTools()">
      <UserAvatar
        v-if="currentUser"
        class="h-8 w-8 sm:h-10 sm:w-10"
        variant="neutral-dark"
        :user="currentUser"
      />
      <div v-else class="h-8 w-8 bg-neutral-600 sm:h-10 sm:w-10"></div>
    </button>
    <div
      :class="[
        'tools-popup rounded visible absolute top-full right-0 -mr-1 flex w-56 transform flex-wrap bg-white px-1 pb-2 pt-3 shadow-md transition-all duration-300',
        { 'invisible -translate-y-1 opacity-0': !toolsOpen },
      ]"
    >
      <ToolsGrid variant="light" @toolsGridClick="closeTools"></ToolsGrid>
    </div>
  </div>
</template>

<script>
import ToolsGrid from "@/components/tools/ToolsGrid";
import ClickOutside from "vue-click-outside";
import UserAvatar from "@/components/common/UserAvatar";

import { mapGetters } from "vuex";

export default {
  directives: {
    ClickOutside,
  },
  components: { ToolsGrid, UserAvatar },
  data() {
    return {
      toolsOpen: false,
    };
  },
  computed: {
    ...mapGetters("user", ["currentUser"]),
    userAvatarName() {
      if (this.$auth.user.name) {
        return this.$auth.user.name;
      }
      return "";
    },
  },
  methods: {
    toggleTools() {
      this.toolsOpen = !this.toolsOpen;
    },
    closeTools() {
      if (this.toolsOpen) {
        this.toolsOpen = false;
      }
    },
  },
};
</script>

<style scoped>
.tools-popup::before {
  content: "";
  @apply absolute right-0 bottom-full mr-4 h-0 w-0 border-8 border-transparent;
  border-bottom-color: white;
  will-change: transform;
}
</style>
