import graphqlClient from "@/utils/graphql";
import CREATE_DISH_PROPOSAL from "@/graphql/CreateDishProposal.gql";
import UPDATE_DISH_PROPOSAL from "@/graphql/UpdateDishProposal.gql";
import router from "@/router";
import store from "@/store";
import axios from "axios";
import env from "@/env";
import i18n from "@/i18n";

export const namespaced = true;

const emptyState = {
  formError: null,
  uuid: null,
  basicInformation: {
    name: "",
    description: "",
    courseUuid: "",
    estimatedPrice: null,
    productMix: null,
    duration: "",
  },

  coverImageFileUuid: "",
  isUploadingCoverImage: false,
  coverImageUploadError: null,
};

export const state = {
  ...emptyState,
};

export const getters = {
  formError: (state) => state.formError,
  coverImageUploadError: (state) => state.coverImageUploadError,
  isUploadingCoverImage: (state) => state.isUploadingCoverImage,
};

export const mutations = {
  resetState: (state) => {
    Object.assign(state, emptyState);
  },
  setBasicInformation: (state, payload) => {
    state.basicInformation = payload;
  },
  resetFormError: (state) => {
    state.formError = null;
  },
  setFormError: (state, error) => {
    state.formError = error;
  },
  setIsUploadingCoverImage: (state, booleanValue) => {
    state.isUploadingCoverImage = booleanValue;
  },
  setCoverImageFileUuid: (state, fileUuid) => {
    state.coverImageFileUuid = fileUuid;
  },
  setCoverImageUploadError: (state, error) => {
    state.coverImageUploadError = error;
  },
  resetCoverImageUploadError: (state) => {
    state.coverImageUploadError = null;
  },
  setDishProposalUuid: (state, payload) => {
    state.uuid = payload;
  },
};

export const actions = {
  resetForm: ({ commit }) => {
    commit("resetState");
  },
  updateBasicInformation: ({ commit }, payload) => {
    commit("setBasicInformation", payload);
  },
  updateDishProposalUuid: ({ commit }, payload) => {
    commit("setDishProposalUuid", payload);
  },
  createDishProposal: async ({ state, commit }) => {
    commit("resetFormError");

    if (
      state.basicInformation.name === "" ||
      state.basicInformation.description === "" ||
      state.basicInformation.courseUuid === ""
    ) {
      commit(
        "setFormError",
        i18n.t("components.community.dishProposalForm.notAllFieldsFilledIn"),
      );
      return;
    }

    try {
      await graphqlClient.mutate({
        mutation: CREATE_DISH_PROPOSAL,
        variables: {
          name: state.basicInformation.name,
          description: state.basicInformation.description,
          coverImageFileUuid: state.coverImageFileUuid,
          courseUuid: state.basicInformation.courseUuid,
          estimatedPrice: parseFloat(state.basicInformation.estimatedPrice),
          productMix: parseFloat(state.basicInformation.productMix),
          duration: state.basicInformation.duration,
        },
      });

      store.dispatch("dishProposals/refetchDishProposals", { root: true });
      store.dispatch("dishProposals/refetchMyDishProposals", { root: true });

      if (router.history.current.name !== "all-proposals") {
        router.push({ name: "all-proposals" });
      }
    } catch (error) {
      commit("setFormError", error.message);
    }
  },
  updateDishProposal: async ({ state, commit }) => {
    commit("resetFormError");

    if (
      state.basicInformation.name === "" ||
      state.basicInformation.description === "" ||
      state.basicInformation.courseUuid === ""
    ) {
      commit(
        "setFormError",
        i18n.t("components.community.dishProposalForm.notAllFieldsFilledIn"),
      );
      return;
    }

    try {
      await graphqlClient.mutate({
        mutation: UPDATE_DISH_PROPOSAL,
        variables: {
          uuid: state.uuid,
          name: state.basicInformation.name,
          description: state.basicInformation.description,
          coverImageFileUuid: state.coverImageFileUuid,
          courseUuid: state.basicInformation.courseUuid,
          estimatedPrice: parseFloat(state.basicInformation.estimatedPrice),
          productMix: parseFloat(state.basicInformation.productMix),
          duration: state.basicInformation.duration,
        },
      });

      store.dispatch("dishProposals/refetchDishProposals", { root: true });
      store.dispatch("dishProposals/refetchMyDishProposals", { root: true });
    } catch (error) {
      commit("setFormError", error.message);
    }
  },
  uploadCoverImage: async ({ commit }, file) => {
    commit("setIsUploadingCoverImage", true);
    commit("resetCoverImageUploadError");

    const bodyFormData = new FormData();
    bodyFormData.append("file", file);

    try {
      const response = await axios({
        method: "post",
        url: `${env.value("VUE_APP_API_HTTP")}/media/upload/image`,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      });

      commit("setCoverImageFileUuid", response.data.fileUuid);
      commit("setIsUploadingCoverImage", false);
    } catch (error) {
      commit("setCoverImageUploadError", error.message);
      commit("setIsUploadingCoverImage", false);
    }
  },
  updateCoverImageFileUuid: ({ commit }, payload) => {
    commit("setCoverImageFileUuid", payload);
  },
  removeCoverImage: ({ commit }) => {
    commit("setCoverImageFileUuid", null);
    commit("resetCoverImageUploadError");
  },
};
