<template>
  <img
    v-if="resizedSrc && loaded"
    crossorigin="anonymous"
    :src="resizedSrc"
    :alt="alt"
  />
  <div
    v-else
    class="absolute top-0 left-0 flex h-full w-full items-center justify-center"
  >
    <IconBorettis class="h-32 w-32 animate-pulse text-neutral-300" />
  </div>
</template>

<script>
export default {
  name: "ResizedImage",

  props: {
    src: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      required: false,
      default: "",
    },
    width: {
      type: Number,
      required: false,
      default: 400,
    },
    height: {
      type: Number,
      required: false,
      default: 400,
    },
    crop: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => {
    return {
      resizedSrc: null,
      loaded: false,
    };
  },

  watch: {
    $props: {
      handler() {
        this.resize(this.src);
      },
      deep: true,
      immediate: true,
    },
  },

  mounted: async function () {
    this.resize(this.src);
  },

  methods: {
    resize(currentImage) {
      this.loaded = false;
      const img = new Image();

      const width = this.width ? this.width : 400;
      const height = this.height ? this.height : 400;

      img.setAttribute("crossorigin", "anonymous");
      img.src = currentImage;

      img.onload = () => {
        const scale = Math.max(
          this.width / img.width,
          this.height / img.height,
        );
        const elem = document.createElement("canvas");
        elem.width = width;
        elem.height = height;
        const ctx = elem.getContext("2d");

        if (this.crop) {
          const imageWidth = img.width * scale;
          const imageHeight = img.height * scale;

          const dx = (width - imageWidth) / 2;
          const dy = (height - imageHeight) / 2;

          ctx.drawImage(
            img,
            0,
            0,
            img.width,
            img.height,
            dx,
            dy,
            imageWidth,
            imageHeight,
          );
        } else {
          ctx.drawImage(img, 0, 0, width, height);
        }

        const dataurl = elem.toDataURL("image/png");

        this.resizedSrc = dataurl;
      };

      this.loaded = true;
    },
  },
};
</script>
