import graphqlClient from "@/utils/graphql";
import FIND_ORGANISATIONS_WITHOUT_CORE_MENU from "@/graphql/FindOrganisationsWithoutCoreMenu.gql";
import CREATE_CORE_MENU from "@/graphql/CreateCoreMenu.gql";
import UPDATE_CORE_MENU from "@/graphql/UpdateCoreMenu.gql";
import FIND_CORE_MENUS from "@/graphql/FindCoreMenus.gql";
import { sortAsc } from "@/utils/sortArray";
import router from "@/router";

export const namespaced = true;

export const state = {
  error: null,
  menu: null,
  dishes: [],
  organisation: null,
  organisations: [],
  organisationsError: null,
  formError: null,
  coreMenus: [],
  currentCoreMenu: null,
  coreMenuError: null,
};

export const getters = {
  menu: (state) => state.menu,
  dishes: (state) => state.dishes,
  coreMenus: (state) => state.coreMenus.sort(sortAsc("createdAt")),
  currentCoreMenu: (state) => state.currentCoreMenu,
  coreMenuError: (state) => state.coreMenuError,
  courses: (state, getters, rootState, rootGetters) => {
    const courses = rootGetters["dish/dishCourses"];

    return courses.map((course) => {
      course.dishes = [];

      state.dishes.map((dish) => {
        if (
          dish.course.uuid === course.uuid &&
          !course.dishes.find((d) => d.uuid === dish.uuid)
        ) {
          course.dishes.push(dish);
        }
      });

      return course;
    });
  },
  formError: (state) => state.formError,
  organisation: (state) => state.organisation,
  organisations: (state) => state.organisations,
  organisationsError: (state) => state.organisationsError,
  isValid: (state, getters) => {
    let isValid = true;

    getters.courses.map((course) => {
      if (
        course.dishes.length !== course.limit ||
        (!course.dishes.length && course.limit > 0)
      ) {
        isValid = false;
      }
    });

    if (!state.organisation) {
      return false;
    }

    return isValid;
  },
  isEditing: (state) => {
    return state.currentCoreMenu ? true : false;
  },
};

export const mutations = {
  addDish: (state, dish) => {
    state.dishes.push(dish);
  },

  removeDish: (state, dish) => {
    const i = state.dishes.map((d) => d.uuid).indexOf(dish.uuid);

    if (i === -1) {
      return;
    }

    state.dishes.splice(i, 1);
  },

  setFormError: (state, error) => {
    state.formError = error;
  },

  setDishes: (state, dishes) => {
    state.dishes = dishes;
  },

  resetDishes: (state) => {
    state.dishes = [];
  },

  resetCurrentCoreMenu: (state) => {
    state.currentCoreMenu = null;
  },

  resetOrganisation: (state) => {
    state.organisation = null;
  },

  setOrganisation: (state, organisation) => {
    state.organisation = organisation;
  },

  setOrganisations: (state, organisations) => {
    state.organisations = organisations;
  },

  setOrganisationsError: (state, error) => {
    state.organisationsError = error;
  },

  setChangeRequests: (state, changeRequests) => {
    state.changeRequests = changeRequests;
    state.changeRequestsLoaded = true;
  },

  setError: (state, error) => {
    state.error = error;
  },

  resetError: (state) => {
    state.error = null;
  },

  setCoreMenus: (state, coreMenus) => {
    state.coreMenus = coreMenus;
  },

  setCurrentCoreMenu: (state, currentCoreMenu) => {
    state.currentCoreMenu = currentCoreMenu;
  },
};

export const actions = {
  addDish({ commit }, dish) {
    commit("addDish", dish);
  },

  removeDish({ commit }, dish) {
    commit("removeDish", dish);
  },

  fetchOrganisations: async ({ commit }) => {
    try {
      const response = await graphqlClient.query({
        query: FIND_ORGANISATIONS_WITHOUT_CORE_MENU,
      });

      const organisations = response.data.organisations;

      commit("setOrganisations", organisations);
    } catch (error) {
      commit("setOrganisationsError", JSON.stringify(error.message));
    }
  },

  setOrganisation({ commit }, organisation) {
    commit("setOrganisation", organisation);
  },

  createCoreMenu: async ({ state, commit }) => {
    try {
      await graphqlClient.mutate({
        mutation: CREATE_CORE_MENU,
        variables: {
          dishesUuids: state.dishes.map((dish) => dish.uuid),
          organisationUuid: state.organisation,
        },
      });

      router.push({ name: "core-menus" });
      commit("resetDishes");
    } catch (error) {
      commit("setFormError", error.message);
    }
  },

  updateCoreMenu: async ({ state, commit }) => {
    try {
      await graphqlClient.mutate({
        mutation: UPDATE_CORE_MENU,
        variables: {
          uuid: state.currentCoreMenu.uuid,
          dishesUuids: state.dishes.map((dish) => dish.uuid),
          organisationUuid: state.organisation,
        },
      });

      commit("resetDishes");
      commit("resetCurrentCoreMenu");

      router.push({ name: "core-menus" });
    } catch (error) {
      commit("setFormError", error.message);
    }
  },

  fetchCoreMenus: async ({ commit }) => {
    try {
      const response = await graphqlClient.query({
        query: FIND_CORE_MENUS,
      });

      const coreMenus = response.data.coreMenus;

      commit("resetError");
      commit("setCoreMenus", coreMenus);
    } catch (error) {
      commit("setError", JSON.stringify(error.message));
    }
  },

  editCoreMenu: ({ commit }, coreMenu) => {
    commit("setCurrentCoreMenu", coreMenu);
    commit("setOrganisation", coreMenu.organisation.uuid);
    commit("setDishes", coreMenu.dishes);

    router.push({ name: "master-dish-list" });
  },

  resetDishes({ commit }) {
    commit("resetDishes");
  },

  resetCoreMenu({ commit, getters }) {
    if (getters.isEditing) {
      commit("resetOrganisation");
      router.push({ name: "core-menus" });
    }

    commit("resetDishes");
    commit("resetCurrentCoreMenu");
  },
};
