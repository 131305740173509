<template>
  <div
    :class="[
      'relative flex flex-shrink-0 items-center justify-center rounded-full font-medium uppercase',
      { 'bg-primary-50 text-primary-400': variant === 'primary' },
      { 'bg-secondary-50 text-secondary-400': variant === 'secondary' },
      { 'bg-neutral-600 text-neutral-200': variant === 'neutral-dark' },
      { 'bg-neutral-50 text-neutral-300': variant === 'neutral' },
      { 'bg-danger-50 text-danger-600': variant === 'danger' },
      { 'h-6 w-6 text-xs': size === 'xs' },
      { 'h-8 w-8': size === 'sm' },
      { 'h-10 w-10': size === 'md' },
      { 'h-12 w-12 text-lg': size === 'lg' },
      { 'h-16 w-16 text-xl': size === 'xl' },
    ]"
  >
    <span v-if="user.firstName">{{ user.firstName.charAt(0) }}</span>
    <span v-if="user.lastName">{{ user.lastName.charAt(0) }}</span>
    <span v-if="!user.firstName && !user.lastName">?</span>
    <IconCrown v-if="hasCrown" class="absolute bottom-full -mb-2 h-6 w-6" />
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
    variant: {
      type: String,
      default: "primary",
      validator: function (value) {
        return (
          ["primary", "secondary", "neutral-dark", "neutral", "danger"].indexOf(
            value,
          ) !== -1
        );
      },
    },
    size: {
      type: String,
      default: "lg",
      validator: function (value) {
        return ["xs", "sm", "md", "lg", "xl"].indexOf(value) !== -1;
      },
    },
    hasCrown: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
