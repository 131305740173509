<template>
  <div class="flex min-w-0 justify-between">
    <div class="truncate">{{ label }}</div>
    <div class="text-neutral-900">{{ text }}</div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    text: {
      type: Number,
      required: true,
    },
  },
};
</script>
