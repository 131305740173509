import graphqlClient from "@/utils/graphql";
import FIND_PRE_OPENINGS from "@/graphql/FindPreOpenings.gql";
import { sortAsc, sortDesc } from "@/utils/sortArray";

export const namespaced = true;

export const state = {
  preOpenings: null,
  preOpeningsLoaded: false,
  error: null,
};

export const getters = {
  preOpenings: (state) => state.preOpenings,
  preOpeningsSortedByCreationDate: (state) =>
    state.preOpenings.sort(sortAsc("createdAt")),
  preOpeningsSortedByEndDate: (state) =>
    state.preOpenings.sort(sortDesc("endDate")),
  error: (state) => state.error,
};

export const mutations = {
  setPreOpenings: (state, preOpenings) => {
    state.preOpenings = preOpenings;
    state.preOpeningsLoaded = true;
  },
  resetPreOpenings: (state) => {
    state.preOpenings = null;
    state.preOpeningsLoaded = false;
  },
  setError: (state, error) => {
    state.error = error;
  },
  resetError: (state) => {
    state.error = null;
  },
};

export const actions = {
  refetchPreOpenings: async ({ commit, dispatch }) => {
    commit("resetPreOpenings");
    dispatch("fetchPreOpenings");
  },
  fetchPreOpenings: async ({ commit }) => {
    try {
      const response = await graphqlClient.query({
        query: FIND_PRE_OPENINGS,
      });

      const preOpenings = response.data.preOpenings;

      commit("resetError");
      commit("setPreOpenings", preOpenings);
    } catch (error) {
      commit("setError", JSON.stringify(error.message));
    }
  },
};
