<template>
  <div>
    <Navbar v-if="$auth.isAuthenticated"></Navbar>
    <div v-if="routeLoading" class="absolute top-16 left-0 right-0 mx-auto w-8">
      <LoadingIndicator class="mt-16" />
    </div>
    <div :class="{ 'hidden md:block': !contentVisible }">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
      <div v-if="!routeLoading" class="container">
        <Footer></Footer>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/navigation/Navbar";
import Footer from "@/components/navigation/Footer";
import { mapGetters } from "vuex";

export default {
  components: { Navbar, Footer },
  computed: {
    ...mapGetters(["routeLoading"]),
    ...mapGetters("content", ["contentVisible"]),
  },
};
</script>
