<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      d="M19.88 18.47c.44-.7.7-1.51.7-2.39c0-2.49-2.01-4.5-4.5-4.5s-4.5 2.01-4.5 4.5s2.01 4.5 4.49 4.5c.88 0 1.7-.26 2.39-.7L21.58 23L23 21.58l-3.12-3.11zm-3.8.11a2.5 2.5 0 0 1 0-5a2.5 2.5 0 0 1 0 5zm-.36-8.5c-.74.02-1.45.18-2.1.45l-.55-.83l-3.8 6.18l-3.01-3.52l-3.63 5.81L1 17l5-8l3 3.5L13 6l2.72 4.08zm2.59.5c-.64-.28-1.33-.45-2.05-.49L21.38 2L23 3.18l-4.69 7.4z"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "IcOutlineQueryStats",
};
</script>
