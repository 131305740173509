<template>
  <div class="flex w-full flex-wrap" @click="emitClick">
    <router-link
      v-if="$can('read:organisations')"
      to="/user-management"
      :class="[
        'rounded group inline-block flex cursor-pointer flex-col items-center px-2 py-3 transition-all duration-200',
        {
          'w-1/2 text-neutral-400 hover:text-neutral-600 active:text-neutral-800':
            variant === 'light',
        },
        {
          'mr-2 w-1/4 bg-neutral-700 py-4 text-neutral-400 hover:bg-neutral-600 hover:text-neutral-100 sm:mr-4':
            variant === 'dark',
        },
      ]"
    >
      <IconUserGroup />
      <span
        :class="[
          'mt-1 text-center text-xs-relaxed transition-all transition-all duration-200 duration-200',
          {
            'text-neutral-600 group-hover:text-neutral-800': variant == 'light',
          },
        ]"
      >
        {{ $t("components.tools.toolsGrid.manageUsers") }}
      </span>
    </router-link>
    <div
      :class="[
        'rounded group inline-block flex cursor-pointer flex-col items-center px-2 py-3 transition-all duration-200',
        {
          'w-1/2 text-neutral-400 hover:text-neutral-600 active:text-neutral-800':
            variant === 'light',
        },
        {
          'mr-2 w-1/4 bg-neutral-700 py-4 text-neutral-400 hover:bg-neutral-600 hover:text-neutral-100 sm:mr-4':
            variant === 'dark',
        },
      ]"
      @click="logout"
    >
      <IconPower />
      <span
        :class="[
          'mt-1 text-center text-xs-relaxed transition-all duration-200',
          {
            'text-neutral-600 group-hover:text-neutral-800': variant == 'light',
          },
        ]"
      >
        {{ $t("components.tools.toolsGrid.logout") }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
      required: true,
      default: "light",
      validator: (value) => ["light", "dark"].indexOf(value) !== -1,
    },
  },

  methods: {
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
    emitClick() {
      this.$emit("toolsGridClick");
    },
  },
};
</script>
