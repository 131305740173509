<template>
  <div class="mb-5 grid gap-5 md:grid-cols-2 lg:mb-8 lg:gap-8 xl:grid-cols-4">
    <div v-if="statsError" class="md:col-span-2 xl:col-span-4">
      {{ statsError }}
    </div>

    <LandingCard v-if="$can('write:assetPackages')">
      <template v-slot:title>
        {{ translations.brandMarketing }}
      </template>
      <template v-slot:content>
        <StatsRow
          v-if="brandMarketingStats"
          :label="translations.uploadedAssets"
          :text="brandMarketingStats.totalAssetCount"
        />
        <StatsRow
          v-if="brandMarketingStats"
          :label="translations.packages"
          :text="brandMarketingStats.totalAssetPackagesCount"
        />
        <StatsRow
          v-if="brandMarketingStats"
          :label="translations.expiringAssets"
          :text="brandMarketingStats.assetPackagesExpiringCount"
        />
      </template>
      <template v-slot:bottom>
        <BaseButton
          size="medium"
          variant="outline"
          :text="translations.createPackage"
          @buttonClick="redirectToCreatePackage"
        >
        </BaseButton>
        <BaseButton
          size="medium"
          variant="fill"
          :text="translations.manageAllAssets"
          @buttonClick="redirectToAllAssets"
        >
        </BaseButton>
      </template>
    </LandingCard>
    <LandingCard
      v-if="!$can('write:assetPackages') && $can('read:assetPackages')"
    >
      <template v-slot:title>
        {{ translations.brandMarketing }}
      </template>
      <template v-slot:content>
        {{ translations.brandMarketingDescription }}
      </template>
      <template v-slot:bottom>
        <BaseButton
          size="medium"
          variant="fill"
          :text="translations.viewPackages"
          @buttonClick="redirectToPackages"
        >
        </BaseButton>
      </template>
    </LandingCard>
    <LandingCard v-if="$can('write:menu')">
      <template v-slot:title>
        {{ translations.menus }}
      </template>
      <template v-slot:content>
        <StatsRow
          v-if="menuStats"
          :label="translations.dishesInMasterDishList"
          :text="menuStats.totalDishesCount"
        />
        <StatsRow
          v-if="menuStats"
          :label="translations.createdMenus"
          :text="menuStats.totalMenusCount"
        />
        <StatsRow
          v-if="menuStats"
          :label="translations.openChangeRequests"
          :text="menuStats.totalChangeRequestCount"
        />
      </template>
      <template v-slot:bottom>
        <BaseButton
          size="medium"
          variant="outline"
          :text="translations.addNewDish"
          @buttonClick="redirectToCreateDish"
        >
        </BaseButton>
        <BaseButton
          size="medium"
          variant="fill"
          :text="translations.manageMasterDishList"
          @buttonClick="redirectToMasterDishList"
        >
        </BaseButton>
      </template>
    </LandingCard>
    <LandingCard v-if="!$can('write:menu') && $can('read:menu')">
      <template v-slot:title>
        {{ translations.menus }}
      </template>
      <template v-slot:content>
        {{ translations.menusDescription }}
      </template>
      <template v-slot:bottom>
        <BaseButton
          size="medium"
          variant="fill"
          :text="translations.viewMasterDishList"
          @buttonClick="redirectToMasterDishList"
        >
        </BaseButton>
      </template>
    </LandingCard>
    <LandingCard v-if="$can('read:preOpenings')">
      <template v-slot:title>
        {{ translations.operations }}
      </template>
      <template v-if="$can('write:preOpenings')" v-slot:content>
        <StatsRow
          v-if="operationStats"
          :label="translations.activePreOpenings"
          :text="operationStats.activePreOpeningsCount"
        />
        <StatsRow
          v-if="operationStats"
          :label="translations.preOpeningsInHandover"
          :text="operationStats.totalPreOpeningCount"
        />
        <StatsRow
          v-if="operationStats"
          :label="translations.preOpeningsOverdue"
          :text="operationStats.preOpeningsOverDueCount"
        />
      </template>
      <template v-else v-slot:content>
        {{ translations.operationsDescription }}
      </template>
      <template v-slot:bottom>
        <BaseButton
          size="medium"
          variant="fill"
          :text="translations.managePreOpenings"
          @buttonClick="redirectToPreOpenings"
        />
      </template>
    </LandingCard>
    <LandingCard v-if="$can('write:organisations')">
      <template v-slot:title>
        {{ translations.userManagement }}
      </template>
      <template v-slot:content>
        <StatsRow
          v-if="userStats"
          :label="translations.users"
          :text="userStats.totalUserCount"
        />
        <StatsRow
          v-if="userStats"
          :label="translations.activeUsers"
          :text="userStats.activeUserCount"
        />
        <StatsRow
          v-if="userStats"
          :label="translations.organisations"
          :text="userStats.totalOrganizationCount"
        />
        <StatsRow
          v-if="userStats"
          :label="translations.stores"
          :text="userStats.totalStoreCount"
        />
      </template>
      <template v-slot:bottom>
        <BaseButton
          size="medium"
          variant="outline"
          :text="translations.createUser"
          @buttonClick="redirectToAuth0"
        >
          <template #iconLeft>
            <IconAuth0 class="mr-0 text-neutral-300" />
          </template>
          <template #iconRight>
            <IconAuth0 class="opacity-0" />
          </template>
        </BaseButton>
        <BaseButton
          size="medium"
          variant="fill"
          :text="translations.manageUsers"
          @buttonClick="redirectToManageUsers"
        >
        </BaseButton>
      </template>
    </LandingCard>
    <LandingCard v-if="$can('read:community') && !$can('write:menu')">
      <template v-slot:title>
        {{ translations.community }}
      </template>
      <template v-slot:content>
        {{ translations.communityDescription }}
      </template>
      <template v-slot:bottom>
        <BaseButton
          size="medium"
          variant="fill"
          :text="translations.viewProposals"
          @buttonClick="redirectToAllProposals"
        >
        </BaseButton>
      </template>
    </LandingCard>
    <LandingCard
      v-if="$can('read:externalLinks')"
      :class="{
        'col-span-2': $allowed([
          'borettisAdmin',
          'masterFranchise',
          'franchisee',
        ]),
        'col-span-1': !$allowed([
          'borettisAdmin',
          'masterFranchise',
          'franchisee',
          'restaurantManagement',
          'shiftLeader',
          'trainerKitchen',
          'trainerService',
        ]),
      }"
    >
      <template v-slot:title>
        {{ translations.extern }}
      </template>
      <template v-slot:buttonContent>
        <BaseButton
          v-if="$allowed(['borettisAdmin', 'masterFranchise', 'franchisee'])"
          size="medium"
          variant="outline"
          :text="translations.hanos"
          @buttonClick="redirectToHanos"
        >
          <template #iconLeft>
            <IconHanos class="mr-0 text-neutral-300" />
          </template>
        </BaseButton>
        <BaseButton
          v-if="$allowed(['borettisAdmin', 'masterFranchise', 'franchisee'])"
          size="medium"
          variant="outline"
          :text="translations.horeko"
          @buttonClick="redirectToHoreko"
        >
          <template #iconLeft>
            <IconHoreko class="mr-0 text-neutral-300" />
          </template>
        </BaseButton>
        <BaseButton
          v-if="$allowed(['borettisAdmin', 'masterFranchise', 'franchisee'])"
          size="medium"
          variant="outline"
          :text="translations.pos"
          @buttonClick="redirectToPOS"
        >
          <template #iconLeft>
            <IconPos class="mr-0 text-neutral-300" />
          </template>
        </BaseButton>
        <BaseButton
          v-if="$allowed(['borettisAdmin', 'masterFranchise', 'franchisee'])"
          size="medium"
          variant="outline"
          :text="translations.recruitee"
          @buttonClick="redirectToRecruitee"
        >
          <template #iconLeft>
            <IconRecruitee class="mr-0 text-neutral-300" />
          </template>
        </BaseButton>
        <BaseButton
          v-if="
            $allowed([
              'borettisAdmin',
              'masterFranchise',
              'franchisee',
              'restaurantManagement',
              'shiftLeader',
              'staff',
            ])
          "
          size="medium"
          variant="outline"
          :text="translations.hubper"
          @buttonClick="redirectToHubper"
        >
          <template #iconLeft>
            <IconHubper class="mr-0 text-neutral-300" />
          </template>
        </BaseButton>
        <BaseButton
          v-if="
            $allowed([
              'borettisAdmin',
              'masterFranchise',
              'franchisee',
              'restaurantManagement',
            ])
          "
          size="medium"
          variant="outline"
          :text="translations.yourBI"
          @buttonClick="redirectToYourBI"
        >
          <template #iconLeft>
            <IconStats class="mr-0 text-neutral-300" />
          </template>
        </BaseButton>
      </template>
    </LandingCard>
    <Espressione class="md:col-span-2" />
  </div>
</template>

<script>
import LandingCard from "@/components/landing/LandingCard";
import Espressione from "@/components/landing/Espressione";
import StatsRow from "@/components/landing/StatsRow";
import env from "@/env";
import { mapGetters } from "vuex";

export default {
  components: {
    LandingCard,
    StatsRow,
    Espressione,
  },
  data() {
    return {
      translations: {
        brandMarketing: this.$t("components.landing.brandMarketing"),
        extern: this.$t("components.landing.extern"),
        brandMarketingDescription: this.$t(
          "components.landing.brandMarketingDescription",
        ),
        uploadedAssets: this.$t("components.landing.uploadedAssets"),
        packages: this.$t("components.landing.packages"),
        expiringAssets: this.$t("components.landing.expiringAssets"),
        createPackage: this.$t("components.landing.createPackage"),
        manageAllAssets: this.$t("components.landing.manageAllAssets"),
        viewPackages: this.$t("components.landing.viewPackages"),
        menus: this.$t("components.landing.menus"),
        menusDescription: this.$t("components.landing.menusDescription"),
        community: this.$t("components.landing.community"),
        communityDescription: this.$t(
          "components.landing.communityDescription",
        ),
        viewProposals: this.$t("components.landing.viewProposals"),
        dishesInMasterDishList: this.$t(
          "components.landing.dishesInMasterDishList",
        ),
        createdMenus: this.$t("components.landing.createdMenus"),
        openChangeRequests: this.$t("components.landing.openChangeRequests"),
        addNewDish: this.$t("components.landing.addNewDish"),
        manageMasterDishList: this.$t(
          "components.landing.manageMasterDishList",
        ),
        viewMasterDishList: this.$t("components.landing.viewMasterDishList"),
        operations: this.$t("components.landing.operations"),
        operationsDescription: this.$t(
          "components.landing.operationsDescription",
        ),
        activePreOpenings: this.$t("components.landing.activePreOpenings"),
        preOpeningsInHandover: this.$t(
          "components.landing.preOpeningsInHandover",
        ),
        preOpeningsOverdue: this.$t("components.landing.preOpeningsOverdue"),
        managePreOpenings: this.$t("components.landing.managePreOpenings"),
        userManagement: this.$t("components.landing.userManagement"),
        organisations: this.$t("components.landing.organisations"),
        stores: this.$t("components.landing.stores"),
        users: this.$t("components.landing.users"),
        activeUsers: this.$t("components.landing.activeUsers"),
        createUser: this.$t("components.landing.createUser"),
        manageUsers: this.$t("components.landing.manageUsers"),
        hanos: "Purchase",
        horeko: "Kitchen manager",
        pos: "Tools",
        recruitee: "Recruitment",
        hubper: "E-learning",
        yourBI: "BI Tool",
      },
    };
  },
  computed: {
    ...mapGetters("user", ["currentUser"]),

    ...mapGetters("stats", [
      "brandMarketingStats",
      "menuStats",
      "operationStats",
      "userStats",
      "statsError",
    ]),
  },

  methods: {
    redirectToCreatePackage() {
      this.$router.push({
        name: "brand-marketing-packages",
        params: {
          openPackageCreateModal: true,
        },
      });
    },
    redirectToPackages() {
      this.$router.push({
        name: "brand-marketing-packages",
      });
    },
    redirectToAllAssets() {
      this.$router.push({
        name: "brand-marketing-all-assets",
      });
    },
    redirectToCreateDish() {
      this.$router.push({
        name: "create-dish",
      });
    },
    redirectToMasterDishList() {
      this.$router.push({
        name: "master-dish-list",
      });
    },
    redirectToPreOpenings() {
      this.$router.push({
        name: "pre-opening-overview",
      });
    },
    redirectToAllProposals() {
      this.$router.push({
        name: "all-proposals",
      });
    },
    redirectToAuth0() {
      const url = "https://manage.auth0.com/";

      window.open(url, "_blank");
    },
    redirectToHanos() {
      const countryCode = this.currentUser?.organisation?.country?.code;
      let url = "https://www.hanos.nl/nl/login";

      if (countryCode === "NLD") {
        url =
          env.value("VUE_APP_HANOS_NL_URL") || "https://www.hanos.nl/nl/login";
      } else if (countryCode === "BEL") {
        url =
          env.value("VUE_APP_HANOS_BE_URL") || "https://www.hanos.be/nl/login";
      }

      window.open(url, "_blank");
    },
    redirectToHoreko() {
      const url =
        env.value("VUE_APP_HOREKO_URL") ||
        "https://app.horeko.com/Account/LogOn";

      window.open(url, "_blank");
    },
    redirectToHubper() {
      const url =
        env.value("VUE_APP_HUBPER_URL") ||
        "https://boretti.horecahero.nl/login";

      window.open(url, "_blank");
    },
    redirectToRecruitee() {
      const url =
        env.value("VUE_APP_RECRUITEE_URL") ||
        "https://app.recruitee.com/#/dashboard/";

      window.open(url, "_blank");
    },
    redirectToPOS() {
      const url =
        env.value("VUE_APP_POS_URL") || "https://wm.untill.com/Boretti";

      window.open(url, "_blank");
    },
    redirectToYourBI() {
      const url =
        env.value("VUE_APP_YOURBI_URL") ||
        "https://yourbinl.sharepoint.com/sites/trattoriaborettiglobal";

      window.open(url, "_blank");
    },
    redirectToManageUsers() {
      this.$router.push({
        name: "users",
      });
    },
  },
};
</script>
