<template>
  <div
    class="relative flex h-full flex-col items-center justify-center rounded-sm bg-cover bg-center p-12 text-center"
    :style="{ backgroundImage: `url(${quoteImage})` }"
  >
    <div class="mb-6 text-xs font-bold uppercase text-neutral-900">
      {{ $t("components.landing.espressione.title") }}
    </div>
    <div
      class="mb-2 font-serif text-xl font-bold tracking-tight text-secondary-600"
    >
      &ldquo;{{ expression.italian }}&rdquo;
    </div>
    <div class="text-sm text-neutral-700">
      &ldquo;{{ expression.translation }}&rdquo;
    </div>
  </div>
</template>

<script>
import quoteImage from "@/assets/images/landing/quote.png";
export default {
  data() {
    return {
      quoteImage,
      expressions: [
        {
          italian: "Tutto finisce a tarallucci e vino.",
          translation: "It all ends with biscuits and wine.",
        },
        {
          italian: "Avere il prosciutto sugli occhi.",
          translation: "To have ham over your eyes.",
        },
        {
          italian: "Sono pieno come un uovo.",
          translation: "I am full as an egg.",
        },
        {
          italian: "Conosco i miei polli.",
          translation: "I know my chicken.",
        },
        {
          italian: "Mangiare per vivere e non vivere per mangiare.",
          translation: "Eat to live, don’t live to eat.",
        },
        {
          italian: "Non fare il salame.",
          translation: "Don’t act like salame.",
        },
        {
          italian: "Fare una spaghettata.",
          translation: "To eat spaghetti.",
        },
      ],
    };
  },

  computed: {
    expression() {
      return this.expressions[(this.expressions.length * Math.random()) | 0];
    },
  },
};
</script>
