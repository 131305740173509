import env from "@/env";
import graphqlClient from "@/utils/graphql";
import FIND_ASSET_PACKAGES from "@/graphql/FindAssetPackages.gql";

export const namespaced = true;

export const state = {
  resourcesCategoryUuid: env.value(
    "VUE_APP_PRE_OPENING_RESOURCES_CATEGORY_UUID",
  ),
  assetPackages: null,
};

export const getters = {
  assetPackages: (state) => state.assetPackages,
};

export const mutations = {
  setAssetPackages: (state, assetPackages) => {
    state.assetPackages = assetPackages;
  },
};

export const actions = {
  fetchAssetPackages: async ({ state, commit }) => {
    const response = await graphqlClient.query({
      query: FIND_ASSET_PACKAGES,
      variables: {
        categories: [state.resourcesCategoryUuid],
      },
    });

    commit("setAssetPackages", response.data.assetPackages);
  },
};
