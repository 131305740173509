export const state = {
  routeLoading: true,
};

export const getters = {
  routeLoading: (state) => state.routeLoading,
};

export const mutations = {
  setRouteLoadingStatus: (state, payload) => {
    state.routeLoading = payload;
  },
};

export const actions = {
  setRouteLoading: ({ commit }, payload) => {
    commit("setRouteLoadingStatus", payload);
  },
};
