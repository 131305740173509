class PermissionGuard {
  constructor(store) {
    this.store = store;
  }

  onlyAllowPermission(permission) {
    return (_to, _from, next) => {
      const hasPermission =
        this.store.getters["permissions/hasPermission"](permission);

      if (hasPermission) {
        return next();
      }

      return next({ name: "not-authorized" });
    };
  }
}

export default PermissionGuard;
