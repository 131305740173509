import graphqlClient from "@/utils/graphql";
import FIND_DISH_PROPOSALS from "@/graphql/FindDishProposals.gql";
import FIND_MY_DISH_PROPOSALS from "@/graphql/FindMyDishProposals.gql";
import VOTE_ON_DISH_PROPOSAL from "@/graphql/VoteOnDishProposal.gql";
import COMMENT_ON_DISH_PROPOSAL from "@/graphql/CommentOnDishProposal.gql";
import { sortAsc } from "@/utils/sortArray";

export const namespaced = true;

export const state = {
  dishProposals: [],
  myDishProposals: [],
  dishProposalsLoaded: false,
  myDishProposalsLoaded: false,
  error: null,
};

export const getters = {
  dishProposals: (state) => state.dishProposals.sort(sortAsc("createdAt")),
  myDishProposals: (state) => state.myDishProposals.sort(sortAsc("createdAt")),
  myOpenDishProposals: (state) =>
    state.myDishProposals.filter((proposal) => proposal.status === "OPEN"),
  myClosedDishProposals: (state) =>
    state.myDishProposals.filter((proposal) => proposal.status != "OPEN"),
  votes: () => (dishProposal, voteValue) => {
    const votes = dishProposal.votes.reduce((accumulator, vote) => {
      if (vote.value === voteValue) {
        return (accumulator += 1);
      }
      return accumulator;
    }, 0);

    return votes;
  },
  upvotes: (state, getters) => (dishProposal) =>
    getters.votes(dishProposal, "UP"),
  downvotes: (state, getters) => (dishProposal) =>
    getters.votes(dishProposal, "DOWN"),
  hasVoted: () => (dishProposal, user, voteValue) => {
    const vote = dishProposal.votes.find(
      (vote) => vote.user.uuid === user.uuid && vote.value === voteValue,
    );
    return !!vote;
  },
  hasUpvoted: (state, getters) => (dishProposal, user) =>
    getters.hasVoted(dishProposal, user, "UP"),
  hasDownvoted: (state, getters) => (dishProposal, user) =>
    getters.hasVoted(dishProposal, user, "DOWN"),
  error: (state) => state.error,
};

export const mutations = {
  setDishProposals: (state, dishProposals) => {
    state.dishProposals = dishProposals;
    state.dishProposalsLoaded = true;
  },
  setMyDishProposals: (state, myDishProposals) => {
    state.myDishProposals = myDishProposals;
    state.myDishProposalsLoaded = true;
  },
  setError: (state, error) => {
    state.error = error;
  },
  resetError: (state) => {
    state.error = null;
  },
  resetDishProposalLoaded: (state) => {
    state.dishProposalsLoaded = false;
  },
  resetMyDishProposalLoaded: (state) => {
    state.myDishProposalsLoaded = false;
  },
};

export const actions = {
  refetchDishProposals: async ({ commit, dispatch }) => {
    commit("resetDishProposalLoaded");
    dispatch("fetchDishProposals");
  },
  refetchMyDishProposals: async ({ commit, dispatch }) => {
    commit("resetMyDishProposalLoaded");
    dispatch("fetchMyDishProposals");
  },
  fetchDishProposals: async ({ commit }) => {
    try {
      if (state.dishProposalsLoaded) {
        return;
      }

      const response = await graphqlClient.query({
        query: FIND_DISH_PROPOSALS,
      });

      const dishProposals = response.data.dishProposals;

      commit("resetError");
      commit("setDishProposals", dishProposals);
    } catch (error) {
      commit("setError", JSON.stringify(error.message));
    }
  },
  fetchMyDishProposals: async ({ commit }) => {
    try {
      if (state.myDishProposalsLoaded) {
        return;
      }

      const response = await graphqlClient.query({
        query: FIND_MY_DISH_PROPOSALS,
      });

      const dishProposals = response.data.myDishProposals;

      commit("resetError");
      commit("setMyDishProposals", dishProposals);
    } catch (error) {
      commit("setError", JSON.stringify(error.message));
    }
  },
  voteOnDishProposal: async (
    { commit, dispatch },
    { dishProposalUuid, value },
  ) => {
    try {
      await graphqlClient.mutate({
        mutation: VOTE_ON_DISH_PROPOSAL,
        variables: {
          dishProposalUuid,
          value,
        },
      });

      dispatch("refetchDishProposals");
    } catch (error) {
      commit("setError", JSON.stringify(error.message));
    }
  },
  upvoteDishProposal: async ({ dispatch }, dishProposal) => {
    dispatch("voteOnDishProposal", {
      dishProposalUuid: dishProposal.uuid,
      value: "UP",
    });
  },
  downvoteDishProposal: async ({ dispatch }, dishProposal) => {
    dispatch("voteOnDishProposal", {
      dishProposalUuid: dishProposal.uuid,
      value: "DOWN",
    });
  },
  addComment: async ({ commit, dispatch }, { dishProposalUuid, text }) => {
    try {
      await graphqlClient.mutate({
        mutation: COMMENT_ON_DISH_PROPOSAL,
        variables: {
          dishProposalUuid,
          text,
        },
      });
      commit("resetError");
      dispatch("refetchDishProposals");
    } catch (error) {
      commit("setError", JSON.stringify(error.message));
    }
  },
};
