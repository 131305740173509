import { createApolloClient } from "vue-cli-plugin-apollo/graphql-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import env from "@/env";
import { getInstance } from "@/auth/index";
import { setContext } from "apollo-link-context";

export const authApolloMiddleware = setContext(() => {
  const authService = getInstance();

  return authService
    .getTokenSilently()
    .then((token) => ({ headers: { Authorization: `Bearer ${token}` } }));
});

const cache = new InMemoryCache({
  dataIdFromObject: (object) => object.uuid || null,
  addTypename: false,
});

const { apolloClient } = createApolloClient({
  httpEndpoint: env.value("VUE_APP_GRAPHQL_HTTP"),
  link: authApolloMiddleware,
  cache: cache,
  apollo: {
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "no-cache",
      },
      query: {
        fetchPolicy: "no-cache",
      },
    },
  },
});
export default apolloClient;
