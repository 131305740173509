import graphqlClient from "@/utils/graphql";
import SET_STAGE_STATUS from "@/graphql/SetStageStatus.gql";
import FIND_SINGLE_PRE_OPENING from "@/graphql/FindSinglePreOpening.gql";

export const namespaced = true;

export const state = {
  preOpening: null,
  error: null,
};

export const getters = {
  currentPreOpening: (state) => state.preOpening,
  stages: (state) =>
    state.preOpening.stages.sort(
      (a, b) => a.details.sortIndex - b.details.sortIndex,
    ),
  todo: (state) =>
    state.preOpening.stages.filter((stage) => stage.status === "todo"),
  inProgress: (state) =>
    state.preOpening.stages.filter((stage) => stage.status === "inProgress"),
  done: (state) =>
    state.preOpening.stages.filter((stage) => stage.status === "done"),
  currentStage: (state, getters) => {
    const combinedTodoInProgress = [...getters.todo, ...getters.inProgress];

    if (combinedTodoInProgress.length === 0) {
      return null;
    }

    const sortedStages = combinedTodoInProgress.sort(
      (a, b) => a.dueDate - b.dueDate,
    );
    return sortedStages[0];
  },
  error: (state) => state.error,
};

export const mutations = {
  optimisticStageUpdate: (state, { payload, status }) => {
    const stageUuids = payload.map((stage) => stage.details.uuid);

    state.preOpening.stages = state.preOpening.stages.map((stage) => {
      if (stageUuids.includes(stage.details.uuid)) {
        return { ...stage, status: status };
      }
      return stage;
    });
  },
  updateStage: (state, newStage) => {
    state.preOpening.stages = state.preOpening.stages.map((stage) => {
      if (stage.details.uuid === newStage.details.uuid) {
        return newStage;
      }

      return stage;
    });
  },
  setPreOpening: (state, preOpening) => {
    state.preOpening = preOpening;
  },
  setError: (state, error) => {
    state.error = error;
  },
};

export const actions = {
  updateStatus: async ({ commit, dispatch }, { items, status }) => {
    const newItem = items.find((item) => item.status !== status);
    commit("optimisticStageUpdate", { payload: items, status });

    if (newItem !== undefined) {
      dispatch("persistStatus", {
        preOpeningUuid: newItem.preOpeningUuid,
        preOpeningStageUuid: newItem.details.uuid,
        status,
      });
    }
  },
  persistStatus: async ({ commit, state }, { preOpeningStageUuid, status }) => {
    let response;

    try {
      response = await graphqlClient.mutate({
        mutation: SET_STAGE_STATUS,
        variables: {
          preOpeningUuid: state.preOpening.uuid,
          preOpeningStageUuid,
          status,
        },
      });

      const updatedStage = response.data.setStageStatus;

      commit("updateStage", updatedStage);
    } catch (error) {
      commit("setError", JSON.stringify(error.message));
    }
  },

  fetchSinglePreOpening: async ({ commit }, preOpeningUuid) => {
    try {
      const response = await graphqlClient.query({
        query: FIND_SINGLE_PRE_OPENING,
        variables: {
          uuid: preOpeningUuid,
        },
      });

      const preOpening = response.data.preOpening;
      preOpening.stages = preOpening.stages.sort(
        (a, b) => a.details.sortIndex - b.details.sortIndex,
      );

      commit("setPreOpening", preOpening);
    } catch (error) {
      commit("setError", JSON.stringify(error.message));
    }
  },
};
