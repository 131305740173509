var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{class:[
    'focus:outline-none inline-flex min-w-0 cursor-pointer items-center rounded-sm text-sm font-medium transition-all duration-300 focus:shadow-focus',
    // Base variants
    { 'shadow-inner': _vm.variant === 'fill' },
    { border: _vm.variant === 'outline' },
    { '': _vm.variant === 'alpha' },

    // Positioning
    { 'justify-center': !_vm.fullWidth },
    { 'justify-between': _vm.fullWidth },

    // Fill button color
    {
      'bg-primary-600 text-white hover:bg-primary-500':
        _vm.variant === 'fill' && _vm.fillColor === 'primary' && !_vm.disabled,
    },
    {
      'cursor-not-allowed bg-neutral-200 text-white shadow-none':
        _vm.variant === 'fill' && _vm.fillColor === 'primary' && _vm.disabled,
    },
    {
      'bg-secondary-600 text-white hover:bg-secondary-700':
        _vm.variant === 'fill' && _vm.fillColor === 'secondary' && !_vm.disabled,
    },
    {
      'bg-secondary-300 text-white':
        _vm.variant === 'fill' && _vm.fillColor === 'secondary' && _vm.disabled,
    },
    {
      'bg-neutral-800 text-white hover:bg-neutral-600':
        _vm.variant === 'fill' && _vm.fillColor === 'neutral' && !_vm.disabled,
    },
    {
      'bg-neutral-400 text-white':
        _vm.variant === 'fill' && _vm.fillColor === 'neutral' && _vm.disabled,
    },
    {
      'bg-white text-neutral-800 hover:bg-neutral-50':
        _vm.variant === 'fill' && _vm.fillColor === 'white' && !_vm.disabled,
    },
    {
      'bg-neutral-100 text-neutral-800':
        _vm.variant === 'fill' && _vm.fillColor === 'white' && _vm.disabled,
    },

    // Outline button color
    {
      'border-neutral-100 hover:border-neutral-200':
        _vm.variant === 'outline' && _vm.outlineColor === 'light', //@TODO: disabled state is missing
    },
    {
      'border-neutral-600 text-white hover:border-neutral-50':
        _vm.variant === 'outline' && _vm.outlineColor === 'dark',
    },

    // Alpha button color
    {
      'bg-alpha-light-10 hover:bg-alpha-light-20':
        _vm.variant === 'alpha' && _vm.alphaColor === 'light', //@TODO: disabled state is missing
    },
    {
      'bg-alpha-dark-10 text-white hover:bg-alpha-dark-20':
        _vm.variant === 'alpha' && _vm.alphaColor === 'dark',
    },

    // Sizes
    { 'h-8 px-4 py-1': _vm.size === 'small' },

    { 'h-10 py-2': _vm.size === 'medium' },
    { 'px-5': _vm.size === 'medium' && _vm.hasText },
    { 'px-2': _vm.size === 'medium' && !_vm.hasText },
    { 'pl-3': _vm.size === 'medium' && _vm.hasIconLeft && _vm.hasText },
    { 'pr-3': _vm.size === 'medium' && _vm.hasIconRight && _vm.hasText },

    { 'h-12': _vm.size === 'large' },
    { 'px-6': _vm.size === 'large' && _vm.hasText },
    { 'px-3': _vm.size === 'large' && !_vm.hasText },
    { 'pl-3': _vm.size === 'large' && _vm.hasIconLeft && _vm.hasText },
    { 'pr-3': _vm.size === 'large' && _vm.hasIconRight && _vm.hasText },

    { 'py-4': _vm.size === 'extraLarge' },
    { 'px-8 ': _vm.size === 'extraLarge' && _vm.hasText },
    { 'px-4 ': _vm.size === 'extraLarge' && !_vm.hasText },
    { 'pl-4': _vm.size === 'extraLarge' && _vm.hasIconLeft && _vm.hasText },
    { 'pr-4': _vm.size === 'extraLarge' && _vm.hasIconRight && _vm.hasText },

    { 'group w-full': _vm.fullWidth } ],attrs:{"disabled":_vm.disabled},on:{"click":_vm.emitClick}},[_c('div',{class:[
      { 'mr-2': _vm.size === 'medium' && _vm.hasIconLeft && _vm.hasText },
      { 'mr-3': _vm.size === 'large' && _vm.hasIconLeft && _vm.hasText },
      { 'mr-4': _vm.size === 'extraLarge' && _vm.hasIconLeft && _vm.hasText },
      { 'w-10': _vm.fullWidth && _vm.hasIconRight },
      {
        'flex w-8 justify-center': _vm.fullWidth && _vm.hasIconLeft,
      } ]},[_vm._t("iconLeft")],2),_c('div',{staticClass:"truncate w-full text-center"},[_vm._v(" "+_vm._s(_vm.text)+" "),_vm._t("iconCenter")],2),_c('div',{class:[
      { 'ml-2': _vm.size === 'medium' && _vm.hasIconRight && _vm.hasText },
      { 'ml-3': _vm.size === 'large' && _vm.hasIconRight && _vm.hasText },
      { 'ml-4': _vm.size === 'extraLarge' && _vm.hasIconRight && _vm.hasText },
      { 'w-10': _vm.fullWidth && _vm.hasIconLeft },

      {
        'flex w-8 justify-center': _vm.fullWidth && _vm.hasIconRight,
      } ]},[_vm._t("iconRight")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }