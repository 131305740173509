import Vue from "vue";

class Environment {
  values = {};

  /* eslint-disable */
  constructor() {
    const keys = Object.keys(config);

    for (let key of keys) {
      this.values[key] = config[key];
    }
  }
  /* eslint-enable */

  value(key, defaultValue = "") {
    const value = this.values[key] || process.env[key];

    if (value === null || value === undefined) {
      return defaultValue;
    }

    if (typeof value === "number") {
      return value;
    }
    if (typeof value === "string" && value.length === 0) {
      return defaultValue;
    }

    return value;
  }
}

const environmentInstance = new Environment();

const EnvironmentVue = {
  install(V) {
    V.prototype.$env = environmentInstance;
  },
};
Vue.use(EnvironmentVue);

export default environmentInstance;
