import axios from "axios";
import env from "@/env";
import graphqlClient from "@/utils/graphql";
import router from "@/router";
import { floatValidationRegex } from "@/validation/floatValidationPattern";
import { integerValidationRegex } from "@/validation/integerValidationPattern";
import CREATE_DISH from "@/graphql/CreateDish.gql";
import EDIT_DISH from "@/graphql/EditDish.gql";

export const namespaced = true;

const emptyState = {
  formError: null,

  uuid: "",

  basicInformation: {
    name: "",
    description: "",
    servings: "",
    prepTime: "",
    courseUuid: "",
    cutlery: 0,
  },

  coverImageFileUuid: "",
  isUploadingCoverImage: false,
  coverImageUploadError: null,

  ingredients: [],
  preparation: [],
  assembly: [],
  allergens: [],

  preparationVideoInfo: {
    title: "",
    presenter: "",
  },
  preparationVideoUuid: "",

  preparationVideoUploadError: null,

  editSuccessMessage: false,
  isUploadingPreparationVideo: false,
};

export const state = {
  ...emptyState,
};

export const getters = {
  editSuccessMessage: (state) => state.editSuccessMessage,
  formError: (state) => state.formError,
  coverImageUploadError: (state) => state.coverImageUploadError,
  preparationVideoUploadError: (state) => state.preparationVideoUploadError,
  isBasicInformationValid: (state) => {
    return (
      !state.isUploadingCoverImage &&
      !state.coverImageUploadError &&
      state.basicInformation.name !== "" &&
      state.basicInformation.description !== "" &&
      state.basicInformation.servings !== "" &&
      state.basicInformation.prepTime !== "" &&
      integerValidationRegex.test(state.basicInformation.servings) &&
      integerValidationRegex.test(state.basicInformation.prepTime) &&
      state.basicInformation.courseUuid !== ""
    );
  },
  isIngredientsValid: (state) => {
    return state.ingredients.every((ingredient) => {
      return (
        ingredient.amount !== "" &&
        floatValidationRegex.test(ingredient.amount) &&
        ingredient.unit !== "" &&
        ingredient.name !== ""
      );
    });
  },
  isPreparationValid: (state, getters) => {
    return (
      getters.isPreparationVideoValid &&
      state.preparation.every((preparation) => {
        return preparation.text !== "";
      })
    );
  },
  isPreparationVideoValid: (state) => {
    let isUploadedVideoValid = true;

    if (
      state.preparationVideoUuid !== "" &&
      (state.preparationVideoInfo.title === "" ||
        state.preparationVideoInfo.presenter === "")
    ) {
      isUploadedVideoValid = false;
    }

    return !state.isUploadingPreparationVideo && isUploadedVideoValid;
  },
  isAssemblyValid: (state) => {
    return state.assembly.every((assembly) => {
      return assembly.text !== "";
    });
  },
  isAllergensValid: (state) => {
    return state.allergens.every((allergen) => {
      return allergen.text !== "";
    });
  },
  isFormValid: (state, getters) => {
    return (
      getters.isBasicInformationValid &&
      getters.isIngredientsValid &&
      getters.isPreparationValid &&
      getters.isAssemblyValid &&
      getters.isAllergensValid
    );
  },
  isUploadingCoverImage: (state) => state.isUploadingCoverImage,
  isUploadingPreparationVideo: (state) => state.isUploadingPreparationVideo,
};

export const mutations = {
  resetState: (state) => {
    Object.assign(state, emptyState);
  },
  setBasicInformation: (state, payload) => {
    state.basicInformation = payload;
  },
  setIsUploadingCoverImage: (state, booleanValue) => {
    state.isUploadingCoverImage = booleanValue;
  },
  setCoverImageFileUuid: (state, fileUuid) => {
    state.coverImageFileUuid = fileUuid;
  },
  setFormError: (state, error) => {
    state.formError = error;
  },
  resetFormError: (state) => {
    state.formError = null;
  },
  setCoverImageUploadError: (state, error) => {
    state.coverImageUploadError = error;
  },
  resetCoverImageUploadError: (state) => {
    state.coverImageUploadError = null;
  },
  setIngredients: (state, payload) => {
    state.ingredients = payload;
  },
  setPreparation: (state, payload) => {
    state.preparation = payload;
  },
  setAssembly: (state, payload) => {
    state.assembly = payload;
  },
  setAllergens: (state, payload) => {
    state.allergens = payload;
  },
  setDishUuid: (state, payload) => {
    state.uuid = payload;
  },
  setPreparationVideoFileUuid: (state, fileUuid) => {
    state.preparationVideoUuid = fileUuid;
  },
  setPreparationVideoUploadError: (state, error) => {
    state.preparationVideoUploadError = error;
  },
  setPreparationVideoInfo: (state, payload) => {
    state.preparationVideoInfo = payload;
  },
  setPreparationVideoUuid: (state, payload) => {
    state.preparationVideoUuid = payload;
  },
  resetPreparationVideoUploadError: (state) => {
    state.preparationVideoUploadError = null;
  },
  resetPreparationVideo: (state) => {
    state.preparationVideoUuid = "";
    state.preparationVideoInfo = {
      title: "",
      presenter: "",
    };
  },
  setIsUploadingPreparationVideo: (state, booleanValue) => {
    state.isUploadingPreparationVideo = booleanValue;
  },
};

export const actions = {
  resetForm: ({ commit }) => {
    commit("resetState");
  },
  updateBasicInformation: ({ commit }, payload) => {
    commit("setBasicInformation", payload);
  },
  uploadCoverImage: async ({ commit }, file) => {
    commit("setIsUploadingCoverImage", true);
    commit("resetCoverImageUploadError");

    const bodyFormData = new FormData();
    bodyFormData.append("file", file);

    try {
      const response = await axios({
        method: "post",
        url: `${env.value("VUE_APP_API_HTTP")}/media/upload/image`,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      });

      commit("setCoverImageFileUuid", response.data.fileUuid);
      commit("setIsUploadingCoverImage", false);
    } catch (error) {
      commit("setCoverImageUploadError", error.message);
      commit("setIsUploadingCoverImage", false);
    }
  },
  updateCoverImageFileUuid: ({ commit }, payload) => {
    commit("setCoverImageFileUuid", payload);
  },
  removeCoverImage: ({ commit }) => {
    commit("setCoverImageFileUuid", null);
    commit("resetCoverImageUploadError");
  },
  updateIngredients: ({ commit }, payload) => {
    commit("setIngredients", payload);
  },
  updatePreparation: ({ commit }, payload) => {
    commit("setPreparation", payload);
  },
  updateAssembly: ({ commit }, payload) => {
    commit("setAssembly", payload);
  },
  updateAllergens: ({ commit }, payload) => {
    commit("setAllergens", payload);
  },
  updateDishUuid: ({ commit }, payload) => {
    commit("setDishUuid", payload);
  },
  updatePreparationVideoInfo: ({ commit }, payload) => {
    commit("setPreparationVideoInfo", payload);
  },
  updatePreparationVideoUuid: ({ commit }, payload) => {
    commit("setPreparationVideoUuid", payload);
  },
  uploadPreparationVideo: async ({ commit }, file) => {
    commit("resetPreparationVideoUploadError");
    commit("setIsUploadingPreparationVideo", true);

    const bodyFormData = new FormData();
    bodyFormData.append("file", file);

    try {
      const response = await axios({
        method: "post",
        url: `${env.value("VUE_APP_API_HTTP")}/media/upload/video`,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      });

      commit("setPreparationVideoFileUuid", response.data.fileUuid);
      commit("setIsUploadingPreparationVideo", false);
    } catch (error) {
      commit("setPreparationVideoUploadError", error.message);
      commit("setIsUploadingPreparationVideo", false);
    }
  },
  resetPreparationVideoUploadError: ({ commit }) => {
    commit("resetPreparationVideoUploadError");
  },
  resetPreparationVideo: ({ commit }) => {
    commit("resetPreparationVideo");
  },
  saveNewDish: async ({ state, commit, dispatch }) => {
    commit("resetFormError");

    try {
      await graphqlClient.mutate({
        mutation: CREATE_DISH,
        variables: {
          name: state.basicInformation.name,
          description: state.basicInformation.description,
          coverImageFileUuid: state.coverImageFileUuid,
          servings: parseInt(state.basicInformation.servings),
          prepTime: parseInt(state.basicInformation.prepTime),
          courseUuid: state.basicInformation.courseUuid,
          ingredients: state.ingredients.map((ingredient, index) => {
            return {
              amount: parseFloat(ingredient.amount),
              unit: ingredient.unit,
              name: ingredient.name,
              sortIndex: ingredient?.sortIndex || index + 1,
            };
          }),
          preparation: state.preparation,
          assembly: state.assembly,
          allergens: state.allergens,
          cutlery: parseInt(state.basicInformation.cutlery),
          preparationVideo:
            state.preparationVideoUuid !== ""
              ? {
                  fileUuid: state.preparationVideoUuid,
                  title: state.preparationVideoInfo.title,
                  presenter: state.preparationVideoInfo.presenter,
                }
              : null,
        },
      });

      dispatch(
        "dish/updateActiveCourseUuid",
        state.basicInformation.courseUuid,
        { root: true },
      );

      router.push({ name: "master-dish-list" });
    } catch (error) {
      commit("setFormError", error.message);
    }
  },

  editDish: async ({ state, commit }) => {
    commit("resetFormError");

    try {
      await graphqlClient.mutate({
        mutation: EDIT_DISH,
        variables: {
          uuid: state.uuid,
          name: state.basicInformation.name,
          description: state.basicInformation.description,
          coverImageFileUuid: state.coverImageFileUuid,
          servings: parseInt(state.basicInformation.servings),
          prepTime: parseInt(state.basicInformation.prepTime),
          courseUuid: state.basicInformation.courseUuid,
          ingredients: state.ingredients.map((ingredient, index) => {
            return {
              amount: parseFloat(ingredient.amount),
              unit: ingredient.unit,
              name: ingredient.name,
              sortIndex: ingredient?.sortIndex || index + 1,
            };
          }),
          preparation: state.preparation,
          assembly: state.assembly,
          allergens: state.allergens,
          cutlery: parseInt(state.basicInformation.cutlery),
          preparationVideo:
            state.preparationVideoUuid !== ""
              ? {
                  fileUuid: state.preparationVideoUuid,
                  title: state.preparationVideoInfo.title,
                  presenter: state.preparationVideoInfo.presenter,
                }
              : null,
        },
      });
      state.editSuccessMessage = true;
      setTimeout(() => {
        state.editSuccessMessage = false;
      }, 4000);
    } catch (error) {
      commit("setFormError", error.message);
    }
  },
};
