export const namespaced = true;

export const state = {
  contentVisible: true,
};

export const getters = {
  contentVisible: (state) => state.contentVisible,
};

export const mutations = {
  toggleContentVisibility: (state) => {
    state.contentVisible = !state.contentVisible;
  },
};

export const actions = {
  toggleContentVisibility: ({ commit }) => {
    commit("toggleContentVisibility");
  },
};
