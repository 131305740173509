import graphqlClient from "@/utils/graphql";
import FETCH_BRAND_MARKETING_STATS from "@/graphql/FetchBrandMarketingStats.gql";
import FETCH_MENU_STATS from "@/graphql/FetchMenuStats.gql";
import FETCH_OPERATION_STATS from "@/graphql/FetchOperationStats.gql";
import FETCH_USER_STATS from "@/graphql/FetchUserStats.gql";

export const namespaced = true;

export const state = {
  brandMarketingStats: null,
  menuStats: null,
  operationStats: null,
  userStats: null,
  statsError: null,
};

export const getters = {
  brandMarketingStats: (state) => state.brandMarketingStats,
  menuStats: (state) => state.menuStats,
  operationStats: (state) => state.operationStats,
  userStats: (state) => state.userStats,
  statsError: (state) => state.statsError,
};

export const mutations = {
  setBrandMarketingStats: (state, brandMarketingStats) => {
    state.brandMarketingStats = brandMarketingStats;
  },
  setMenuStats: (state, menuStats) => {
    state.menuStats = menuStats;
  },
  setOperationStats: (state, operationStats) => {
    state.operationStats = operationStats;
  },
  setUserStats: (state, userStats) => {
    state.userStats = userStats;
  },
  setStatsError: (state, error) => {
    state.statsError = error;
  },
  resetStatsError: (state) => {
    state.statsError = null;
  },
};

export const actions = {
  fetchBrandMarketingStats: async ({ commit }) => {
    try {
      const response = await graphqlClient.query({
        query: FETCH_BRAND_MARKETING_STATS,
      });

      const brandMarketingStats = response.data.brandMarketingStats;
      commit("setBrandMarketingStats", brandMarketingStats);
      commit("resetStatsError");
    } catch (error) {
      commit("setStatsError", JSON.stringify(error.message));
    }
  },
  fetchMenuStats: async ({ commit }) => {
    try {
      const response = await graphqlClient.query({
        query: FETCH_MENU_STATS,
      });

      const menuStats = response.data.menuStats;
      commit("setMenuStats", menuStats);
      commit("resetStatsError");
    } catch (error) {
      commit("setStatsError", JSON.stringify(error.message));
    }
  },
  fetchOperationStats: async ({ commit }) => {
    try {
      const response = await graphqlClient.query({
        query: FETCH_OPERATION_STATS,
      });

      const operationStats = response.data.operationStats;
      commit("setOperationStats", operationStats);
      commit("resetStatsError");
    } catch (error) {
      commit("setStatsError", JSON.stringify(error.message));
    }
  },
  fetchUserStats: async ({ commit }) => {
    try {
      const response = await graphqlClient.query({
        query: FETCH_USER_STATS,
      });

      const userStats = response.data.userStats;
      commit("setUserStats", userStats);
      commit("resetStatsError");
    } catch (error) {
      commit("setStatsError", JSON.stringify(error.message));
    }
  },
};
