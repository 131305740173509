<template>
  <div>
    <label
      class="group relative flex cursor-pointer items-center text-sm text-neutral-600"
    >
      <input
        type="checkbox"
        :checked="value"
        :value="value"
        class="absolute top-0 left-0 opacity-0"
        @change="update"
      />
      <div
        class="mr-2 flex h-5 w-5 flex-shrink-0 items-center justify-center rounded-sm border-2 border-neutral-100 bg-white transition-all duration-200 group-hover:border-neutral-300"
      >
        <IconCheckbox class="opacity-0" />
      </div>
      <div class="flex-grow flex justify-between">
        <div>{{ label }}</div>
      </div>
    </label>
  </div>
</template>

<script>
export default {
  name: "Checkbox",
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
  },
  methods: {
    update(event) {
      this.$emit("input", event.target.checked);
    },
  },
};
</script>

<style scoped>
input[type="checkbox"]:checked + div {
  @apply border-primary-50 bg-primary-50;

  & + div {
    @apply text-neutral-900;
  }

  & > svg {
    @apply text-primary-600 opacity-100;
  }
}
</style>
