import graphqlClient from "@/utils/graphql";
import FETCH_USER from "@/graphql/FetchUser.gql";

export const namespaced = true;

export const state = {
  currentUser: null,
};

export const getters = {
  currentUser: (state) => state.currentUser,
  isAllowed: (state) => (allowedRoles) =>
    allowedRoles.includes(state.currentUser?.role?.name),
};

export const mutations = {
  setUser: (state, payload) => {
    state.currentUser = payload;
  },
};

export const actions = {
  fetchUser: async ({ commit }, auth0Identifier) => {
    const response = await graphqlClient.mutate({
      mutation: FETCH_USER,
      variables: { auth0Identifier },
    });

    const user = response.data.findOrCreateUser;

    commit("setUser", user);
  },
};
