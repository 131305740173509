import { getInstance } from "./index";

const login = (to, _from, next, store) => {
  const authService = getInstance();

  const fn = async () => {
    if (authService.isAuthenticated) {
      const claims = await authService.getIdTokenClaims();

      if (store.getters["user/currentUser"] === null) {
        await store.dispatch("user/fetchUser", claims.sub);
      }

      if (store.getters["permissions/hasSetPermissions"]) {
        return next();
      }

      const permissions =
        claims["http://digitaldon.it/authorization"].permissions;

      return store
        .dispatch("permissions/setPermissions", permissions)
        .then(() => next());
    }

    authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
  };

  if (!authService.loading) {
    return fn();
  }

  authService.$watch("loading", (loading) => {
    if (loading === false) {
      return fn();
    }
  });
};

export default login;
